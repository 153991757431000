/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

var Parser = require("html-react-parser");

import {
  fbAppId,
  baseUrl,
  appId,
  apiUrl,
  deliveryId,
  cateringId,
  reservationId,
  languageCd,
} from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPriceValue,
  timeToConv12,
  showLoader,
  hideLoader,
  getGstInclusiveFun,
} from "../Helpers/SettingHelper";

import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "moment";
var qs = require("qs");
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_ACTIVITYCOUNT,
} from "../../actions";

import cookie from "react-cookies";

import scotterImg from "../../common/images/scooter-small.png";
import mapImg from "../../common/images/map-icon.png";
import twoWheeler from "../../common/images/scooter-tab.png";
import reservIcon from "../../common/images/calender-tab.png";
import categoringImg from "../../common/images/categroy-tab.png";
import pickupImg from "../../common/images/maki-bag.png";
import productImg from "../../common/images/noimg-400x400.jpg";

const lang = require("../Helpers/Language/lang" + "-" + languageCd);

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corderdetail: [],
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      catering_orders: 0,
      reservation_orders: 0,
      deliveryTakeaway_orders: 0,
      promotion_count: 0,
      CateringReceiptDetails: [],
      CateringCartItems: [],
      reservationlist: [],
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
  }

  componentDidMount() {
    /* delivery current - past orders */
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);

    $("#dvLoading").fadeOut(2000);

    this.getActivityCounts();
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadpitems();
    });
  }

  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);
  }

  loadpitems() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
  }

  getActivityCounts() {
    const inputKeys = ["overall_orders", "promotionwithoutuqc"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            promotion_count:
              nextProps.activitycount[0].result_set.promotionwithoutuqc,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({ overall_orders: 0, promotion_count: 0 });
    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }

    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }
  }

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var params =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }

  /* CATERING VIEW & PRINT */

  printcateringReceipt(orderId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, cateringId);
  }

  getOrderItemData = (dataProp) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        const orderTime = Moment(item.order_date).format("h:mm A");

        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>
                    {lang.myorders.order_no} - {item.order_local_no}
                  </h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="order_no_deatails">
                {lang.thankyoupage.order_placed} :
                {Moment(item.order_created_on).format("DD/MM/YYYY") +
                  " " +
                  Moment(item.order_created_on).format("h:mm A")}
                <span>
                  {lang.thankyoupage.order_payby} : {item.order_method_name}
                </span>
                {item.order_qrcode_counternumber !== "" &&
                  item.order_qrcode_counternumber !== null && (
                    <span>
                      <b>
                        {"Counter No"} : {item.order_qrcode_counternumber}
                      </b>
                    </span>
                  )}
              </div>
              {item.order_availability_id === deliveryId ? (
                <div className="delivery_total delivery_total_text">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>{lang.cartlist.orderhandl}</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== null
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code,
                        lang.common.countrylable
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>{lang.cartlist.deliverylocation}</h3>
                    <span>
                      {addressFormat(
                        item.order_customer_unit_no1,
                        item.order_customer_unit_no2,
                        item.order_customer_address_line1,
                        item.order_customer_address_line2,
                        item.order_customer_postal_code,
                        lang.common.countrylable
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="delivery_total delivery_total_text pickup-order-div">
                  <div className="delivery_total_left">
                    <img src={pickupImg} />
                    <h3>{lang.cartlist.pickuplocation}</h3>
                    <span>{item.outlet_name}</span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code,
                        lang.common.countrylable
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>
                    {item.order_availability_id === deliveryId
                      ? lang.cartlist.deliverydate
                      : lang.cartlist.pickupdate}
                  </h2>
                  <h4 className="checkoutDate">{orderDate}</h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {item.order_availability_id === deliveryId
                      ? lang.cartlist.deliverytime
                      : lang.cartlist.pickuptime}
                  </h2>
                  <h4 className="checkoutTime">{orderTime}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>{lang.cartlist.totalLbl}</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    <sup>$</sup>
                    {item.order_total_amount}
                  </h3>
                </div>
              </div>

              {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}
              <div className="order_btns">
                {/*<a href="javascript:void(0)" onClick={this.printReceipt.bind(this, item.order_primary_id)}   className="button print_invoice" title="">{lang.myorders.print_invoice}</a>*/}
                <a
                  href="javascript:void(0)"
                  onClick={this.getReceipt.bind(this, item.order_id)}
                  className="button view_recipt"
                  title=""
                >
                  {lang.myorders.view_recipt}
                </a>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">{lang.myorders.no_records}</div>;
    }
  };

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    /*	setTimeout(function () {
		//	console.log('render1',$('#dvLoading').length);		
            $('#dvLoading').remove();
						
					}, 500);*/

    return (
      <div className={"Home selected-lang-" + languageCd}>
        {/* myorders-main-div - start */}
        <div className="container common-top-div myorders-main-div">
          <Header />

          <div className="innersection_wrap myadmin_wrap">
            <div className="mainacc_menusec">
              <div className="mainacc_toptext">
                <h2>{lang.myaccount.title}</h2>
                <p>{lang.myaccount.subtitle}</p>
              </div>

              <div className="mainacc_menuout">
                <ul className="mainacc_menulist">
                  <li>
                    <Link to="/myaccount" title={lang.myaccount.account_plchrd}>
                      <span>{lang.myaccount.account_link}</span>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/myorders" title={lang.myaccount.order_plchrd}>
                      <span>{lang.myaccount.order_link}</span>
                      {parseFloat(this.state.overall_orders) > 0 && (
                        <span id="masterCount">
                          {this.state.overall_orders}
                        </span>
                      )}
                    </Link>
                  </li>
                  <li className="displayDivCls">
                    <Link to="/rewards" title={lang.myaccount.reward_plchrd}>
                      <span>{lang.myaccount.reward_link}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/mypromotions"
                      title={lang.myaccount.promotion_plchrd}
                    >
                      <span>{lang.myaccount.promotion_link}</span>
                      {parseFloat(this.state.promotion_count) > 0 && (
                        <span id="masterCount">
                          {this.state.promotion_count}
                        </span>
                      )}
                    </Link>
                  </li>
                </ul>
                <div className="mbacc_mslidersec mbacc_mslider">
                  <Slider {...settingsMyAcc}>
                    <div className="mbacc_mslide">
                      <Link
                        to="/myaccount"
                        title={lang.myaccount.account_plchrd}
                      >
                        <span>{lang.myaccount.account_link}</span>
                      </Link>
                    </div>
                    <div className="mbacc_mslide active">
                      <Link to="/myorders" title={lang.myaccount.order_plchrd}>
                        <span>{lang.myaccount.order_link}</span>
                      </Link>
                    </div>
                    {/*<div className="mbacc_mslide"><Link to="/rewards" title={lang.myaccount.reward_plchrd}><span>{lang.myaccount.reward_link}</span></Link></div>*/}
                    <div className="mbacc_mslide">
                      <Link
                        to="/mypromotions"
                        title={lang.myaccount.promotion_plchrd}
                      >
                        <span>{lang.myaccount.promotion_link}</span>
                      </Link>
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec" id="ordertab1">
                            <ul className="nav nav-tabs text-center">
                              <li className="active">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn5"
                                  aria-expanded="true"
                                >
                                  <span>{lang.myorders.current_orders}</span>
                                </a>
                              </li>
                              <li className="">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn6"
                                  aria-expanded="false"
                                >
                                  <span>{lang.myorders.past_orders}</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                id="tab-id-inn5"
                                className="tab-pane fade active in"
                              >
                                <h4 className="tab_mobtrigger inner_tab_border active">
                                  {lang.myorders.current_orders}
                                  <i></i>
                                </h4>

                                <div className="tab_mobrow filter_tabin">
                                  <div className="order-delivery">
                                    <div className="ord-body">
                                      <div className="cur-order-body">
                                        <div className="myacc_order_details">
                                          {this.getOrderItemData(
                                            this.state.corderdetail
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data"
                                      onClick={this.loadcurrentItems.bind(this)}
                                      style={{ display: "none" }}
                                    >
                                      {lang.myorders.loat_more}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div id="tab-id-inn6" className="tab-pane fade">
                                <h4 className="tab_mobtrigger inner_tab_border">
                                  {lang.myorders.past_orders}
                                  <i></i>
                                </h4>

                                <div className="tab_mobrow filter_tabin">
                                  <div className="pst-order-body">
                                    <div className="myacc_order_details">
                                      {this.getOrderItemData(
                                        this.state.porderdetail
                                      )}
                                    </div>
                                  </div>

                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data1"
                                      style={{ display: "none" }}
                                      onClick={this.loadpastItems.bind(this)}
                                    >
                                      {lang.myorders.loat_more}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* tab-content - end */}
                          </div>
                        </div>
                        {/* myacc_filter - end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* myorders-main-div - end */}

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup catering_receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.ReceiptDetails}
              cartItems={this.state.CartItems}
            />
          </div>
        </div>

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row oreder_itm_row" key={index}>
          <div className="row oreder-row-inv">
            <div className="col-xs-7 cart_left">
              <div className="cart_img">
                <a href="javascript:void(0)" title="">
                  <img
                    src={item.item_image !== "" ? item.item_image : productImg}
                    alt=""
                  />
                </a>
              </div>
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                  {item.item_discount_amount !== "" &&
                    item.item_discount_amount !== null &&
                    parseFloat(item.item_discount_amount) > 0 && (
                      <p className="product-discount">
                        Discount: $
                        {parseFloat(item.item_discount_amount).toFixed(2)}
                      </p>
                    )}
                </h4>
                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}
              </div>
              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}

              {/*} 
				 <p className ="help-block">Special instruction for individual product items ...</p>
				 {*/}
            </div>
            <div className="col-xs-5 cart_right text-right">
              <div className="cart_price">
                <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div className="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p>";
      }
      return html;
    }
    return "";
  }
  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p>";
      }
      html += "</div>";
    }
    return html;
    console.log(html + "----------");
  }
  componentWillReceiveProps(nextProps) {}

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      var inclusiveGST =
        typeof cookie.load("inclusiveGST") === "undefined"
          ? 0
          : cookie.load("inclusiveGST");
      inclusiveGST = parseFloat(inclusiveGST);

      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <div className="tnk-head">
                  <h2>{lang.cartlist.title}</h2>
                  <a href="javascript:void(0);">
                    {lang.myorders.email_receipt}
                  </a>
                </div>
                <div className="tnk-order">
                  <h3>
                    {lang.thankyoupage.order_no} - {details.order_local_no}
                  </h3>
                  <p>
                    {lang.thankyoupage.order_placed} :{" "}
                    {Moment(details.order_created_on).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                    <br></br>
                    {lang.thankyoupage.order_payby} :{" "}
                    {details.order_method_name}
                  </p>
                </div>
              </div>
            </div>
            <div className="tnk-delivery">
              {details.order_availability_id === deliveryId ? (
                <div className="delivery_total">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>{lang.myorders.delivery_frm}</h3>
                    <span>{details.outlet_name}</span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code,
                        lang.common.countrylable
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>{lang.myorders.delivery_to}</h3>
                    <span>{details.order_customer_address_line1}</span>{" "}
                    <span>Singapore {details.order_customer_postal_code}</span>{" "}
                    <span>
                      {details.order_customer_unit_no1 != "" &&
                      details.order_customer_unit_no2 != ""
                        ? details.order_customer_unit_no1 +
                          "-" +
                          details.order_customer_unit_no2
                        : ""}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="delivery_total pickup-order-div">
                  <div className="delivery_total_left">
                    <img src={pickupImg} />
                    <h3>{lang.cartlist.pickuplocation}</h3>
                    <span>{details.outlet_name}</span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code,
                        lang.common.countrylable
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>
                    {details.order_availability_id === deliveryId
                      ? lang.cartlist.deliverydate
                      : lang.cartlist.pickupdate}
                  </h2>
                  <h4 className="checkoutDate">
                    {Moment(details.order_date).format("DD-MM-YYYY")}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {details.order_availability_id === deliveryId
                      ? lang.cartlist.deliverytime
                      : lang.cartlist.pickuptime}
                  </h2>
                  <h4 className="checkoutTime">
                    {Moment(details.order_date).format("hh:mm A")}
                  </h4>
                </div>
              </div>

              <div className="hcart_tt horder_tt">
                <div className="row-replace">
                  <div className="col-sm-cls text-left">
                    <h3>{lang.myorders.order_items}</h3>
                  </div>
                </div>
              </div>

              <div className="order-items-maindiv">
                {this.getCartDetList(cartItems)}
              </div>

              <div className="cart_footer tnk_cart_footer">
                <div className="cart_row">
                  <div className="row">
                    <div className="col-xs-7">
                      <p className="text-uppercase">
                        {lang.cartlist.subtotalLbl}
                      </p>
                    </div>
                    <div className="col-xs-5 text-right">
                      <span>${details.order_sub_total}</span>
                    </div>
                  </div>
                </div>
                {parseFloat(details.order_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row">
                      <div className="col-xs-7">
                        <p className="text-uppercase">
                          {lang.cartlist.promo_code} (-)
                        </p>
                      </div>
                      <div className="col-xs-5 text-right">
                        <span>${details.order_discount_amount}</span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <div className="row">
                      <div className="col-xs-7">
                        <p className="text-uppercase">
                          {lang.myorders.delivery_charges}
                        </p>
                      </div>
                      <div className="col-xs-5 text-right">
                        <span>
                          $
                          {(
                            parseFloat(details.order_delivery_charge) +
                            parseFloat(details.order_additional_delivery)
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {details.order_tax_calculate_amount > 0 && (
                  <div className="cart_row">
                    <div className="row">
                      <div className="col-xs-7">
                        <p className="text-uppercase">
                          {lang.cartlist.gstLbl}({details.order_tax_charge}%)
                        </p>
                      </div>
                      <div className="col-xs-5 text-right">
                        <span>${details.order_tax_calculate_amount}</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="cart_row cart_footer_totrow grant-total-cls">
                  <div className="row">
                    <div className="col-xs-7">
                      <p className="text-uppercase">{lang.cartlist.totalLbl}</p>
                    </div>
                    <div className="col-xs-5 text-right">
                      <span>${details.order_total_amount}</span>
                    </div>
                  </div>
                  {inclusiveGST > 0 && (
                    <p className="gst-inclusive-lbl">
                      {getGstInclusiveFun(
                        inclusiveGST,
                        parseFloat(details.order_total_amount)
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
