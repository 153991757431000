/* eslint-disable */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import axios from 'axios';
import { validated } from 'react-custom-validation';
import update from 'immutability-helper';
import FacebookLogin from 'react-facebook-login';
import { setMinutes, setHours, getDay, format } from 'date-fns';
var dateFormat = require('dateformat');
var Parser = require('html-react-parser');

import { appId, languageCd, languageNm, languageFg, defaultLang, defaultLangName, defaultLangFlag, availLangFiles, apiUrl, apiUrlV2, apiUrlPro, deliveryId, pickupId, cateringId, reservationId, baseUrl, fbAppId, CountryTxt, siteurlSingapore, siteurlIndonesia } from "../Helpers/Config";
const lang = require('../Helpers/Language/lang'+'-'+languageCd);

import { GET_GLOBAL_SETTINGS, GET_ZONE_DETAIL, GET_PICKUP_OUTLETS, GET_ALL_OUTLETS,DESTROY_CART_DETAIL, GET_LOGINDATA, GET_FBLOGINDATA, GET_FORGET_PASSWORD, GET_REGISTRATION, GET_MENUDATA, GET_ALLUSERSECADDRDATA} from '../../actions';
import { getReferenceID, showAlert, showLoader, hideLoader, getAliasName, stripslashes, removeOrderDateTime, removePromoCkValue, addressFormat } from "../Helpers/SettingHelper";

import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob";
import Autocomplete from "./Autocomplete";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import {Login,Forgotpassword,Signup,Guestcheckout} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo.png";
import footerLogo from "../../common/images/footer-logo.png";
import mobileLogo from "../../common/images/res-logo.svg";
import takeawayimage from "../../common/images/maki-bag.png";
import iconUnhappy from "../../common/images/maki-error.png";
import iconWin from "../../common/images/maki-like.png";
import takeawayOld from "../../common/images/takeaway_old.png";
import scooterBg from "../../common/images/scooter-big.png";
import userProfile from "../../common/images/user-profile.png";
import warningImg from "../../common/images/warning.svg";

import orderNowBgImg from "../../common/images/order-now-bg.png";
import orderNowHvrImg from "../../common/images/order-now-hover.png";

import singaporeImg from "../../common/images/singapore.jpg";
import indonesiaImg from "../../common/images/indonesia.jpg";

import deliveryImg from "../../common/images/maki-scooter.png";
import takeawayImg from "../../common/images/maki-bag.png";
import cateringImg from "../../common/images/catering.svg";
import reservationImg from "../../common/images/reservation.svg";
import loaderimg from "../../common/images/search-load.gif";
import arrowimg from "../../common/images/ar.png";

import CartSideBar from "./CartSideBar";

class Header extends Component {

    constructor(props) {
        super(props);
	
        this.state = {
            st_language:'',st_language_name:'',st_language_flag:'',seletedAvilablityId:'',seletedOutletId:'',order_tat_time:0,globalsettings: [], secondaryaddresslist: [], deliveryOutlets: [], deliveryOutletsList: [], pickupOutlets: [], pickupOutletsList: [], delivery_outlet_id:'', searchProResult: [], selectedProResult: [], orderHandled: '', orderDeliveryAddress: '', nextavail: '', logindata:'', getDateTimeFlg:'', deliveryInfo: [],pickupInfo: [],seleted_ord_date: '',seleted_ord_time: '', fields: {
            email: '',
            password: ''
			},
			fieldsfgtpassword: {
				email: ''
			},
			fieldscheckout: {
                check_email: '',
                check_firstname: '',
                check_lastname: '',
                check_phone: '',
            },
			fpstatus:'initiating',
			regstatus:'initiating',
			fieldssignup: {
				firstname: '',
				lastname: '',
				email: '',
				gender: 'M',
				password: '',
				rePassword: '',
				mobile: '',
				pdpa_consent: '',
				terms: ''

			},
              menuData:[],
			  temp_gmap_lat: '',
			  temp_gmap_lng: '',
			  temp_gmap_address: '',
        };
		
		this.props.getGlobalSettings();
		this.props.getPickupOutlets();
		this.props.getAllOutlets(deliveryId);
		this.props.getSecondaryAddress();
		
		var availbty = (typeof cookie.load('defaultAvilablityId') === 'undefined') ? '' : cookie.load('defaultAvilablityId');
		var outltIdTxt = (typeof cookie.load('orderOutletId') === 'undefined') ? '' : cookie.load('orderOutletId');
		var zoneIdTxt = (typeof cookie.load('orderZoneId') === 'undefined') ? '' : cookie.load('orderZoneId');
		
		var tatTime = (typeof cookie.load('orderTAT') === "undefined") ? '' : cookie.load('orderTAT');
		
		if(availbty === deliveryId && outltIdTxt !== '' && zoneIdTxt !== '') {
			this.state['delivery_outlet_id'] = outltIdTxt;
			this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
		}
		
		this.state['seletedAvilablityId'] = availbty;
		this.state['seletedOutletId'] = outltIdTxt;
		this.state['order_tat_time'] = tatTime;
		
		var deliveryInfo = [], pickupInfo = [];
		if(availbty !== '' && outltIdTxt !== '') {
		  var orderInfoData = [];
		      if(availbty === deliveryId) {
			  orderInfoData['orderZoneId'] = zoneIdTxt;
			  orderInfoData['orderDeliveryAddress'] = cookie.load('orderDeliveryAddress');   	  
			  }
			  orderInfoData['orderOutletId'] = outltIdTxt;
			  orderInfoData['orderOutletName'] = cookie.load('orderOutletName');
			  orderInfoData['orderPostalCode'] = cookie.load('orderPostalCode');
			  orderInfoData['orderTAT'] = cookie.load('orderTAT');
			  orderInfoData['orderHandled'] = cookie.load('orderHandled');
			  orderInfoData['orderHandledByText'] = cookie.load('orderHandledByText');
			  orderInfoData['defaultAvilablityId'] = cookie.load('defaultAvilablityId');
			  if(availbty === pickupId) {
			  orderInfoData['pickupHavingHere'] = cookie.load('pickupHavingHere');
			  orderInfoData['pickupToGo'] = cookie.load('pickupToGo');
			  }
			  
			  deliveryInfo = (availbty === deliveryId) ? orderInfoData : [];
			  pickupInfo = (availbty === pickupId) ? orderInfoData : [];
		}
		this.state['deliveryInfo'] = deliveryInfo;
		this.state['pickupInfo'] = pickupInfo;
		
    }
	
	
	/*forget password  - start*/
	fieldforgot = (field, value) => {
		this.setState(update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } }))
    }
    
    forgotpassword = () => {
        this.setState({fpstatus:'loading'});
        const formPayload = this.state.fieldsfgtpassword;

        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "email_address": formPayload.email,
           "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
		
	   showLoader('forgotpassword-cls','class');
       this.props.getForgetPassword(qs.stringify(postObject));
    }
	/*forget password  - end*/
	
	/* signin - start*/
	fieldChange = (field, value) => {
		this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
	}
	
	handleSignin = () => {
		const formPayload = this.state.fields;
		var qs = require('qs');
		var postObject = {
			"app_id": appId,
			"type": "web",
			"login_username": formPayload.email,
			"login_password": formPayload.password
		};
		showLoader('login_submit','class');
		this.props.getLoginData(qs.stringify(postObject));

	}
   /* signin - end*/
   
   
   /* for signup - start*/
	fieldChangeSignup = (field, value) => {
		if (field == 'terms') {
			value = $("#terms").prop("checked");
		}
		if (field == 'pdpa_consent') {
			value = $("#pdpa_consent").prop("checked");
		}
		this.setState(update(this.state, { fieldssignup: { [field]: { $set: value } } }))
    }
     
    handleSignup = () => {
        
        const formPayload = this.state.fieldssignup;
        this.setState({regstatus:'loading'});
		
		var pdpaConsent = (formPayload.pdpa_consent === true) ? 'yes' : 'no';
        
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.firstname,
            "customer_last_name": formPayload.lastname,
            "customer_email": formPayload.email,
            "customer_password": formPayload.password,
            "customer_phone": formPayload.mobile,
            "customer_gender": formPayload.gender,
            "customer_pdpa_consent": pdpaConsent,
            "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
		
		showLoader('signup_submit','class');
        this.props.getRegistration(qs.stringify(postObject));
 
    }
	/* for signup - end*/
   

	componentWillReceiveProps(PropsDt) {
        
        if(PropsDt.menudata !== this.props.menudata){
          this.setState({menudata: PropsDt.menudata[0].result_set});
        }
        
		if(PropsDt.outletslist !== this.state.pickupOutletsList) {
		  var outletArr=(typeof PropsDt.outletslist !== "undefined")?PropsDt.outletslist : Array();	
		  this.setState({pickupOutlets: outletArr,pickupOutletsList:outletArr});
		}
		
		if(PropsDt.alloutletslist !== this.state.deliveryOutletsList) {	
		  this.setState({deliveryOutlets: PropsDt.alloutletslist,deliveryOutletsList:PropsDt.outletslist});
		}
		
		if(PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
		  var secAdrrss = (typeof PropsDt.secondaryaddresslist !== "undefined")	? PropsDt.secondaryaddresslist : Array();
		  this.setState({secondaryaddresslist: secAdrrss});
		}
		
		if('homePageState' in PropsDt) {
			if(PropsDt.homePageState.nextavail !== undefined && PropsDt.homePageState.nextavail !== '' && PropsDt.homePageState.nextavail !== this.state.nextavail) {
				this.setState({nextavail: PropsDt.homePageState.nextavail});
			}
		}
		
		if(PropsDt.fbloginData!==this.props.fbloginData){
			this.doLogin(PropsDt.fbloginData[0]);
        }
		
        if(PropsDt.logindata !==this.props.logindata){
            this.doLogin(PropsDt.logindata[0]);
        }

		if(this.state.fpstatus==='loading'){
            if(PropsDt.forgetpassword !== undefined){
               this.setState({fpstatus:'ok'});
               this.showMessage(PropsDt.forgetpassword[0])       
            }
		}
          
        if(this.state.regstatus==='loading'){
            if(PropsDt.registration !== undefined){
                this.setState({regstatus:'ok'});
                this.showMessage(PropsDt.registration[0])       
            }
        }
	}
	

   /* main - menu navigation -start*/
	
	createLink(menu){
		if(menu.nav_type==="0" && menu.nav_parent_title == '')
		{
	     return  <Link to={"/page/"+menu.nav_title_slug} title={menu.nav_title}><span>{menu.nav_title}</span></Link>;
		}
		else if(menu.nav_type==="3" &&  menu.nav_parent_title == '')
		{  
           return   <Link to={(menu.nav_pages != '#') ?"/"+menu.nav_pages:''} title={menu.nav_title} target={ (menu.nav_link_type == 'blank') ?'_blank':''}><span>{menu.nav_title}</span></Link>;
		}
		
    }
    
    createSubmenu(menu,type){
		
		if(menu.nav_parent_title === '')
        {
		  if (this.state.menudata) {
			var checkIngVal = 0;  
            var liTxt =  this.state.menudata.map(function (menuparent, i) {
		
			   if(menu.nav_id == menuparent.nav_parent_title){
				  checkIngVal = 1;
				  if(menuparent.nav_type==="0") {
                  return  (<li key={i+100}><Link to={"/page/"+menuparent.nav_title_slug} title={menuparent.nav_title}><span>{menuparent.nav_title}</span></Link></li>);
				  } else {
				  return  (<li key={i+100}><Link to={(menuparent.nav_pages != '#') ?"/"+menuparent.nav_pages:''} title={menuparent.nav_title} target={ (menuparent.nav_link_type == 'blank') ?'_blank':''}><span>{menuparent.nav_title}</span></Link></li>);  
				  }
            }},this);
			
			if(type === 'span' && checkIngVal === 1) {
				return (<span className="submenu-arow"></span>);
			} else if(type === 'ul' && checkIngVal === 1) {
				return (<ul className="submenu_list">{liTxt}</ul>);
			} else {
				return '';
			}
			
         }
		}
	}
	
	menuActiveCls(nav_pages) {
		var currenturl = window.location.href;
		var returnClsTx = '';
		if(nav_pages === 'home' || nav_pages === '#') {
			returnClsTx = (this.props.match.path === '/home' || this.props.match.path === '/') ? "active" : '';
		} else {
			returnClsTx = currenturl.includes(nav_pages) ? "active" : '';
		}
		return returnClsTx;
	}
	

	listMainNavigation(){
		   if (this.state.menudata) {
           return this.state.menudata.map(function (menu, i) {
                  return  (<li key={i+100} className={this.menuActiveCls(menu.nav_pages)}>{this.createLink(menu)}{this.createSubmenu(menu,'span')}{this.createSubmenu(menu,'ul')}</li>);
            },this)
         }
      }
	
	/* menu navigation -end */ 
	/* show message */
	showMessage(response){
           hideLoader('signup_submit','class');
           hideLoader('forgotpassword-cls','class');
           if (response.status === "ok") {
              showAlert(lang.common.alertsuccesstitle, lang.signuppopup.successmsg);
            } else {
                if (response.form_error) {
                   showAlert(lang.common.alerterrortitle, response.form_error);
                 } else {
                    showAlert(lang.common.alerterrortitle, response.message);
                 }
            }
            $.magnificPopup.open({
				items: {
				  src: '.alert_popup'
			    },
			    type: 'inline'
			});
    }
	
	chooseAvailabilityFun(availability){
		
		/*if(availability === deliveryId){
		  return false;
		}*/

		var defaultAvilTy = cookie.load('defaultAvilablityId');
	
		if(defaultAvilTy!==availability){
		  var cartTotalItems = cookie.load('cartTotalItems');
			  cartTotalItems = (cartTotalItems != '' && cartTotalItems != undefined) ? parseInt(cartTotalItems) : 0;
		  if(defaultAvilTy === cateringId && cartTotalItems > 0) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  } else if(cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  }
		} else if(defaultAvilTy===availability && cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined){
			/*$.magnificPopup.close();
			this.props.history.push('/products');
			return false;*/
			
			  var cartTotalItems = cookie.load('cartTotalItems');
				  cartTotalItems = (cartTotalItems != '' && cartTotalItems != undefined) ? parseInt(cartTotalItems) : 0;
			  if(defaultAvilTy === pickupId && cartTotalItems > 0) {
				  this.setState({nextavail:availability});
				  $.magnificPopup.open({
					items: {
					  src: '#warning-popup'
					},
					type: 'inline'
				  });
				  return false;
			  }
		}
		
		
		var popupIdtxt = '';
		if(availability === deliveryId){
		  popupIdtxt = '#delevery-popup';
		  /*return false;*/
		}else if(availability === pickupId){
		  popupIdtxt = '#takeaway-popup';
		} else if(availability === cateringId) {
			cookie.save("defaultAvilablityId",cateringId);
		  	$.magnificPopup.close();
			this.props.history.push('/catering');
			return false;
		} else if(availability === reservationId) {
			cookie.save("defaultAvilablityId",reservationId);
		  	$.magnificPopup.close();
			this.props.history.push('/reservation');
			return false;
		    /*popupIdtxt = '#comingsoon-popup';*/
		}
		
		if(popupIdtxt !== '') {
			$.magnificPopup.open({
				items: {
				  src: popupIdtxt
				},
				type: 'inline'
			});
		}
	  

	}
	
	chooseAvailabilityMbl() {
		var availability = cookie.load('defaultAvilablityId');
		var showtext = lang.navigation.orderNow;
		if(availability === deliveryId){
			showtext = lang.availability.delivery;
		}else if(availability === pickupId){
			showtext = lang.availability.takeaway;
		} else if(availability === cateringId) {
			showtext = lang.availability.catering;
		} else if(availability === reservationId) {
			showtext = lang.availability.reservation;
		}
		return showtext; 
	}
	
	/* facebook login */
	responseFacebook = (response) => {
		if (response.name !== "" && response.email !== "" && response.name !== undefined && response.email !== undefined) {
			var vSplitName = response.name.split(" ");
			var firstname = vSplitName[0];
			var lastname = vSplitName[1];
			var birthday = response.birthday;
			var qs = require('qs');
			var photo = response.picture.data.url;
			/* update gender field */
			var postGender = "";
			if (typeof response.gender !== "undefined" && response.gender === "male") {
				postGender = "M";
			} else if (typeof response.gender !== "undefined" && response.gender === "female") {
				postGender = "F";
			}
			/* update DOB */
			var dob = '';
			if (typeof birthday !== "undefined" && birthday !== "") {
				dob = dateFormat(response.birthday, "yyyy-mm-dd");
			}



			var postObject = {
				"app_id": appId,
				"type": "web",
				"login_firstname": response.first_name,
				"login_lastname": response.last_name,
				"login_username": response.email,
				"customer_fb_id": response.id,
				"customer_gender": postGender,
				"customer_photo": photo,
				"customer_dob": dob
			};
			this.props.getFbLoginData(qs.stringify(postObject));
			
		}
	}

   /* for login and facebook login*/
   doLogin(fbloginData){
	   hideLoader('login_submit','class');
	   if (fbloginData.status === "ok") {
			$.magnificPopup.close();
			var mobileno = '';
			if (typeof fbloginData.result_set.customer_phone === "undefined" || fbloginData.result_set.customer_phone === "null" || fbloginData.result_set.customer_phone === "") {
				mobileno = "";
			} else {
		
				mobileno = fbloginData.result_set.customer_phone;
			}
		
			cookie.save("UserId", fbloginData.result_set.customer_id);
			cookie.save("UserEmail", fbloginData.result_set.customer_email);
			cookie.save("UserFname", (fbloginData.result_set.customer_first_name !== "" ? fbloginData.result_set.customer_first_name : ''));
			cookie.save("UserLname", (fbloginData.result_set.customer_last_name !== "" ? fbloginData.result_set.customer_last_name : ''));
			cookie.save("UserMobile", mobileno);
		
			cookie.save("UserDefaultAddress", fbloginData.result_set.customer_address_name);
			cookie.save("UserDefaultUnitOne", fbloginData.result_set.customer_address_line1);
			cookie.save("UserDefaultUnitTwo", fbloginData.result_set.customer_address_line2);
			cookie.save("UserDefaultPostalCode", fbloginData.result_set.customer_postal_code);

			const{history} = this.props;
			
		   if(cookie.load('cateringPay') === 'start') {
				
				showAlert(lang.common.alertsuccesstitle, lang.loginpopup.successmsg);
				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});
				
				history.push("/payment/"+cookie.load('orderIdTxt')+"/"+cookie.load('paymentIdTxt'));
				/*history.push("/payment");*/
				
		  } else {

			var qs = require('qs');
			var postObject = {
				"app_id": appId,
				"reference_id": getReferenceID(),
				"customer_id": fbloginData.result_set.customer_id,
				"availability_id": cookie.load('defaultAvilablityId')
			};

			axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject)).then(res => {
			    showAlert(lang.common.alertsuccesstitle, lang.loginpopup.successmsg);
				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});
				if (res.data.status === "ok") {
					if(cookie.load('loginpopupTrigger')==='fromcheckout') {
					  cookie.remove('loginpopupTrigger');
					  history.push("/checkout");
					} else if(cookie.load('loginpopupTrigger')==='fromchecatering') {
					  cookie.remove('loginpopupTrigger');
					  history.push("/catering");  
				    } else if(cookie.load('redirectReservatin')==='Yes') {
					  cookie.remove('redirectReservatin');
					  history.push("/reservation");  
					} else {
					  history.push("/myaccount");
					}
				} else {
					if(cookie.load('redirectReservatin')==='Yes') {
					  cookie.remove('redirectReservatin');
					  history.push("/reservation");  
					} else {
						history.push("/myaccount");
					}
				}
			});
			
		 }

		} else {
			cookie.remove('loginpopupTrigger');
			/*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/
			showAlert(lang.common.alerterrortitle, lang.loginpopup.errormsg);
			$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
		}
	}
	
	/*  Onchange  for Guest Checkout */
    fieldChangecheckout = (field, value) => {
        this.setState(update(this.state, { fieldscheckout: { [field]: { $set: value } } }))
    }
	
	handleCheckout = () => {
        const formPayload = this.state.fieldscheckout;
        if (document.getElementById("spn-email-error").innerHTML === '<span class="error">This email already exists</span>') {
            return false;
        }
        if (document.getElementById("spn-mobile-error").innerHTML === '<span class="error">This mobile number already exists</span>') {
            return false;
        }
		
		showLoader('login_in_guest','class');
		
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.check_firstname,
            "customer_last_name": formPayload.check_lastname,
            "customer_email": formPayload.check_email,
            "customer_phone": formPayload.check_phone,
            "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
        
        axios.post(apiUrl + "guestaccount/create", qs.stringify(postObject))
            .then(response => {
                hideLoader('login_in_guest','class');
                if (response.data.status === "ok") {
                    window.scrollTo(0, 0);
                    $.magnificPopup.close();
                   
                    /* set User cookie values - Start */
                    cookie.save("UserId", response.data.result_set.customer_id);
                    cookie.save("UserFname", (response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : ''));
                    cookie.save("UserLname", (response.data.result_set.customer_last_name != "" ? response.data.result_set.customer_last_name : ''));
                    cookie.save("UserMobile", response.data.result_set.customer_phone);
                    cookie.save("UserEmail", response.data.result_set.customer_email);
                    cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name);
                    cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1);
                    cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2);
                    cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code);
                    cookie.save("userAccountType",1);
					const{history} = this.props;
					
					if(cookie.load('cateringPay') === 'start') {
						showAlert(lang.common.alertsuccesstitle, lang.loginpopup.successmsg);
						$.magnificPopup.open({
							items: {
								src: '.alert_popup'
							},
							type: 'inline'
						});
						history.push("/payment/"+cookie.load('orderIdTxt')+"/"+cookie.load('paymentIdTxt'));
						/*history.push("/payment");*/
				    } else {

                    var qs = require('qs');
                    var postObject = {
                        "app_id": appId,
                        "reference_id": getReferenceID(),
                        "customer_id": response.data.result_set.customer_id,
                        "availability_id": cookie.load('defaultAvilablityId')
                    };

                    axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject)).then(res => {
						 showAlert(lang.common.alertsuccesstitle, lang.loginpopup.successmsg);
						 $.magnificPopup.open({
							items: {
								src: '.alert_popup'
							},
							type: 'inline'
						 });
                        if (res.data.status === "ok") {
                            if(cookie.load('loginpopupTrigger')==='fromcheckout') {
							  cookie.remove('loginpopupTrigger');
							  history.push("/checkout");
							} else if(cookie.load('loginpopupTrigger')==='fromchecatering') {
							  cookie.remove('loginpopupTrigger');
							  history.push("/catering");  
							} else if(cookie.load('redirectReservatin')==='Yes') {
							  cookie.remove('redirectReservatin');
							  history.push("/reservation");  
							} else {
							  history.push("/myaccount");
							}
                        }
                        else {
                            if(cookie.load('redirectReservatin')==='Yes') {
							  cookie.remove('redirectReservatin');
							  history.push("/reservation");  
							} else {
								history.push("/myaccount");
							}
                        }
                    });

					}
                    

                } else {
                    if (response.data.form_error) {
                        $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.form_error+'</div>');

                    } else {

                        $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.message+'</div>');

                    }
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
	
	closepopup() {
		$.magnificPopup.open({
		  items: {
			src: '#order-popup'
		  },
		  type: 'inline'
		});
	}
	
	gobckOutletpopup() {
		$.magnificPopup.open({
		  items: {
			src: '#delevery-popup'
		  },
		  type: 'inline'
		});
	}
	
	gobckPkupOutletpopup() {
		$.magnificPopup.open({
		  items: {
			src: '#takeaway-popup'
		  },
		  type: 'inline'
		});
	}
	
	changeCountrypopup() {
		$.magnificPopup.open({
		  items: {
			src: '#country-popup'
		  },
		  type: 'inline'
		});
	}
	
	changeAvailability() {
		
		var tempArr = [], tempVl = '';
		this.setState({seletedOutletId:tempVl,deliveryInfo:tempArr,pickupInfo:tempArr,seleted_ord_date:tempVl,seleted_ord_time:tempVl});
		
		this.destroyCart("Yes");
		
		var popupIdtxt = '';
		if(this.state.nextavail === deliveryId){
		  popupIdtxt = '#delevery-popup';
		}else if(this.state.nextavail === pickupId){
		  popupIdtxt = '#takeaway-popup';
		} else if(this.state.nextavail === cateringId) {
			cookie.save("defaultAvilablityId",cateringId);
		  	$.magnificPopup.close();
			this.props.history.push('/catering');
			return false;
		} else if(this.state.nextavail === reservationId) {
			cookie.save("defaultAvilablityId",reservationId);
		  	$.magnificPopup.close();
			this.props.history.push('/reservation');
			return false;
		    /*popupIdtxt = '#comingsoon-popup';*/
		} else if(this.state.nextavail === 'languagechange') {
			
			var $_this = this;
			setTimeout(function(){
				cookie.save("language",$_this.state.st_language);
				cookie.save("language_name",$_this.state.st_language_name);
				cookie.save("language_flag",$_this.state.st_language_flag);
				
				$.magnificPopup.close();
				window.location.replace('/');
			},1500);
			
			return false;
		}
		
		if(popupIdtxt !== '') {
			$.magnificPopup.open({
				items: {
				  src: popupIdtxt
				},
				type: 'inline'
			});
		}
	}
	
	destroyCart(clear='No') {
		this.props.destroyCartDetail();
		this.deleteOrderCookie(clear);
	}
	
	deleteOrderCookie(clear='Yes') {
		if(clear == 'Yes'){
			cookie.remove('orderZoneId');
			cookie.remove('orderOutletId');
			cookie.remove('outletchosen');        
		}
		
		removeOrderDateTime();
	    removePromoCkValue();
		
		console.log('ckvremove');
		
		cookie.remove('orderPaymentMode');
		cookie.remove('orderDeliveryAddress');
		cookie.remove('orderPostalCode');
		cookie.remove('orderTableNo');
		cookie.remove('product_remarks');
		cookie.remove('orderOutletName');    
		cookie.remove('carttotalitems');
		cookie.remove('cartsubtotal');
		cookie.remove('cartid');
		cookie.remove('firstNavigation');
		
		cookie.remove('pickupHavingHere');
		cookie.remove('pickupToGo');
		
		/* Delivery avilablity */
		cookie.remove('orderDateTime');
		cookie.remove('deliveryDate');
		cookie.remove('deliveryTime');
		cookie.remove('unitNoOne');
		cookie.remove('unitNoTwo');

		cookie.remove('promotion_id');
		cookie.remove('promotion_applied');
		cookie.remove('promotion_code');
		cookie.remove('promotion_delivery_charge_applied');
		cookie.remove('promotion_amount');
		cookie.remove('promotion_category');
		cookie.remove('prmo_type');
		
		/*Remove voucher*/
		cookie.remove('voucher_applied');
		cookie.remove('voucher_code');
		cookie.remove('voucher_amount');
			
		cookie.remove('points_redeemed');
		cookie.remove('points_used');
		cookie.remove('points_amount');
		cookie.remove('prmo_type');
    }
	
	/* find Zone*/
    findOutletBasedZone(first, availability) {

		if (first) {
		  var postalcode = $("#postalcode").val();
		} else {
		  var postalcode = $("#postalcode1").val();
		}
		
		if (postalcode.length < 5) {
		  $(".postal_error").html('<span class="error">'+lang.deliverypopup.postalcodeerror+'</span>');
		  return false;
		}
		
		/*var outletIdTxt = this.state.delivery_outlet_id;

		if (outletIdTxt === '') {
		  $(".postal_error").html('<span class="error">Go Back and Select your delivery outlet.</span>');
		  return false;
		}*/
		
		showLoader('delivery_submit_cls','class'); 
		
		/*var urlWthData = 'outlets/findOutletZone?app_id=' + appId + "&availability_id=" + availability +"&lang_code=" + languageCd + "&postal_code=" + postalcode + "&outlet_id=" + outletIdTxt;*/
		
		var urlWthData = 'outlets/findOutletZone?app_id=' + appId + "&availability_id=" + availability +"&lang_code=" + languageCd + "&postal_code=" + postalcode + "&postalcode_basedoutlet=yes";
		
		axios.get(apiUrlV2 + urlWthData).then(res => {
			
			hideLoader('delivery_submit_cls','class');
			
			var deliveryInfo = [];
			/* Success response */
			if (res.data.status === "ok") {
			  $.magnificPopup.close();

			  cookie.save('outletchosen', availability);

			  var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname;
			  var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", "+lang.common.orderhandledtext + " " + res.data.result_set.outlet_delivery_timing + " "+lang.common.minutestext;
			  
			  deliveryInfo['orderZoneId'] = res.data.result_set.zone_id;
			  deliveryInfo['orderOutletId'] = res.data.result_set.outlet_id;
			  deliveryInfo['orderOutletName'] = stripslashes(res.data.result_set.outlet_name);
			  deliveryInfo['orderPostalCode'] = res.data.result_set.postal_code_information.zip_code;
			  deliveryInfo['orderTAT'] = res.data.result_set.outlet_delivery_timing;
			  deliveryInfo['orderDeliveryAddress'] = orderDeliveryAddress;
			  deliveryInfo['orderHandled'] = orderHandled;
			  deliveryInfo['defaultAvilablityId'] = availability;

			  var unitNum = this.showUnitNum(res.data.result_set.outlet_unit_number1,res.data.result_set.outlet_unit_number2);	
			
			  var orderHandledText = res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", "+unitNum+" "+lang.common.countrylable+" " + postalcode;
			  
			  deliveryInfo['orderHandledByText'] = orderHandledText;
			  
			  
			  this.setState({deliveryInfo: deliveryInfo,seletedAvilablityId: availability, seletedOutletId: res.data.result_set.outlet_id, order_tat_time: res.data.result_set.outlet_delivery_timing, getDateTimeFlg: 'yes', orderHandled: orderHandled, orderDeliveryAddress: orderDeliveryAddress + " "+lang.common.countrylable+" " + res.data.result_set.postal_code_information.zip_code });

			  removeOrderDateTime();
			  removePromoCkValue();

			  $.magnificPopup.open({
				items: {
				  src: '#awesome-popup'
				},
				type: 'inline'
			  });
			  
			}

			/* Error response */
			if (res.data.status === "error") {
			
			  this.setState({deliveryInfo: deliveryInfo});
			  
			  $.magnificPopup.close();
			  $.magnificPopup.open({
				items: {
				  src: '#error-postal-popup'
				},
				type: 'inline'
			  });
			  
			  if (first === 0) {
				var mgsTxt = (res.data.message !== '') ? res.data.message : 'Please enter valid postal code.';  
				$(".postal_error").html('<span class="error">'+mgsTxt+'</span>');
			  }
			  
			}
			

		  });

	}
	
	/* Select outlet */
    selectOutlet(first, availability) {

		if (first) {
		  var postalcode = $("#postalcode").val();
		} else {
		  var postalcode = $("#postalcode1").val();
		}

		if (postalcode.length < 5) {
		  $(".postal_error").html('<span class="error">'+lang.deliverypopup.postalcodeerror+'</span>');
		  return false;
		}
		
		showLoader('delivery_submit_cls','class'); 
		
		axios.get(apiUrl + 'outlets/find_outlet?skip_timing=Yes&app_id=' + appId + "&availability_id=" + availability +"&lang_code=" + languageCd + "&postal_code=" + postalcode)
		  .then(res => {
			
			hideLoader('delivery_submit_cls','class');
			
			/* Success response */
			if (res.data.status === "ok") {
			  $.magnificPopup.close();

			  cookie.save('outletchosen', availability);

			  var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname;
			  var orderHandled = res.data.result_set.outlet_name + ", "+lang.common.orderhandledtext + " " + res.data.result_set.outlet_delivery_timing + " "+lang.common.minutestext;
			  this.setState({seletedAvilablityId: availability, seletedOutletId: res.data.result_set.outlet_id, order_tat_time: res.data.result_set.outlet_delivery_timing, getDateTimeFlg: 'yes', orderHandled: orderHandled, orderDeliveryAddress: orderDeliveryAddress + " "+lang.common.countrylable+" " + res.data.result_set.postal_code_information.zip_code });
			  
			  cookie.save('orderOutletId', res.data.result_set.outlet_id);
			  cookie.save('orderOutletName', res.data.result_set.outlet_name);
			  cookie.save('orderPostalCode', res.data.result_set.postal_code_information.zip_code);
			  cookie.save('orderTAT', res.data.result_set.outlet_delivery_timing);
			  cookie.save('orderDeliveryAddress', orderDeliveryAddress);
			  cookie.save('orderHandled', orderHandled);
			  cookie.save('defaultAvilablityId', availability);

			  var orderHandledText = res.data.result_set.outlet_name + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", "+lang.common.countrylable+" " + postalcode;
			  cookie.save('orderHandledByText', orderHandledText);

			  removeOrderDateTime();
			  removePromoCkValue();

			  $.magnificPopup.open({
				items: {
				  src: '#awesome-popup'
				},
				type: 'inline'
			  });
			  
			}

			/* Error response */
			if (res.data.status === "error") {
			  $.magnificPopup.close();
			  $.magnificPopup.open({
				items: {
				  src: '#error-postal-popup'
				},
				type: 'inline'
			  });
			  
			  if (first === 0) {
				var mgsTxt = (res.data.message !== '') ? res.data.message : lang.deliverypopup.postalcodeerror;  
				$(".postal_error").html('<span class="error">'+mgsTxt+'</span>');
			  }
			  
			}
			

		  });

	}

	selectDatetm() {
		var seletedOutletId = this.state.seletedOutletId;
		var pickupInfo = this.state.pickupInfo;
		if((seletedOutletId !== '') && (Object.keys(pickupInfo).length > 0)){
			
			this.setState({getDateTimeFlg: 'yes'});

			removeOrderDateTime();
			removePromoCkValue();
			  
			$.magnificPopup.open({
				items: {
				  src: '#awesome-popup'
				},
				type: 'inline'
			});
		} else {
			$(".outlet_error").html('<span class="error"> '+lang.takeawaypopup.searchoutleterror+'</span>');
		}
	}
	
	setOrderOutletDateTimeData() {
		
		var seletedOrdDate = this.state.seleted_ord_date;
		var seletedOrdTime = this.state.seleted_ord_time;
		if(seletedOrdDate !== '' && seletedOrdTime !== '' && seletedOrdDate !== null && seletedOrdTime !== null) {
			var orderInfoData = (this.state.seletedAvilablityId === pickupId) ? this.state.pickupInfo : this.state.deliveryInfo;
			if(Object.keys(orderInfoData).length > 0) {
				
				var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
				var OrderHours = seletedOrdTime.getHours();
				var OrderMunts = seletedOrdTime.getMinutes();
				var OrderSecnd = seletedOrdTime.getSeconds();
				var orderDateTime = new Date(OrderDate);
					  orderDateTime.setHours(OrderHours);
					  orderDateTime.setMinutes(OrderMunts);
					  orderDateTime.setSeconds(OrderSecnd);
			
                var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
				var deliveryTime = this.convPad(OrderHours)+":"+this.convPad(OrderMunts)+":"+this.convPad(OrderSecnd);			
				
				cookie.save('orderDateTime', orderDateTime);
				cookie.save('deliveryDate', deliveryDate);
				cookie.save('deliveryTime', deliveryTime);
				
				
				if(this.state.seletedAvilablityId === deliveryId) {
					cookie.save('orderZoneId', orderInfoData['orderZoneId']);
					cookie.save('orderDeliveryAddress', orderInfoData['orderDeliveryAddress']);
				}
				
				cookie.save('orderOutletId', orderInfoData['orderOutletId']);
			    cookie.save('orderOutletName', orderInfoData['orderOutletName']);
			    cookie.save('orderPostalCode', orderInfoData['orderPostalCode']);
				cookie.save('orderTAT', orderInfoData['orderTAT']);
				cookie.save('orderHandled', orderInfoData['orderHandled']);
				cookie.save('defaultAvilablityId', orderInfoData['defaultAvilablityId']);
				cookie.save('orderHandledByText', orderInfoData['orderHandledByText']);
				
				if(this.state.seletedAvilablityId === pickupId) {
				 cookie.save('pickupHavingHere', orderInfoData['pickupHavingHere']);
				 cookie.save('pickupToGo', orderInfoData['pickupToGo']);
			    }
			  
				
				cookie.save('outletchosen', orderInfoData['defaultAvilablityId']);
			    $.magnificPopup.close();
			    if(cookie.load('popuptriggerFrom') === 'FeaturedPro') {
				   cookie.remove('popuptriggerFrom');
				   this.props.history.push('/');
			    } else {
				   if(window.location.pathname === '/products') {
				   this.props.history.push('/refpage/gotoproduct'); 	   
                   } else {					   
				   this.props.history.push('/products');
				   }
			    }
				
			} else {
				$.magnificPopup.open({
					items: {
					  src: '#outlet-error-popup'
					},
					type: 'inline'
			    });
			}
			
		} else {
			$(".ordrdatetime_error").html('<span class="error"> Please select order date and time.</span>');
		}
		
	}
	
	convPad(d) {
		return (d < 10) ? '0' + d.toString() : d.toString();
    }
	
	gotoProducts() {
		if((cookie.load('orderOutletId'))== undefined || (cookie.load('orderOutletId')) == '' ){
			$(".outlet_error").html('<span class="error"> '+lang.takeawaypopup.searchoutleterror+'</span>');		
		}else{
		  cookie.save('outletchosen', cookie.load('defaultAvilablityId'));
		  $.magnificPopup.close();
		  if(cookie.load('popuptriggerFrom') === 'FeaturedPro') {
			  cookie.remove('popuptriggerFrom');
			  this.props.history.push('/');
		  } else {
			  this.props.history.push('/products');
		  }
		}
    }
	
	selectDlyOutlet() {
		if(this.state.delivery_outlet_id === '' ){
		 $('.delivery_outletpoup').find(".outlet_error").html('<span class="error"> '+lang.takeawaypopup.searchoutleterror+'</span>');
		} else {
		  $.magnificPopup.close();
		  $.magnificPopup.open({
				items: {
				  src: '#delevery-postcode-popup'
				},
				type: 'inline'
		  });
		}
    }
	
	selectDlyZoneBestGmap(availability) {
		
		/*var outletIdTxt = this.state.delivery_outlet_id;

		if (outletIdTxt === '') {
		  $(".gmap_addrr_error").html('<span class="error">Go Back and Select your delivery outlet.</span>');
		  return false;
		}*/
		
		var tempGmapLat = this.state.temp_gmap_lat;
		var tempGmapLng = this.state.temp_gmap_lng;
		var tempGmapAddress = this.state.temp_gmap_address;
		if(tempGmapLat !== '' && tempGmapLng !== '' && tempGmapAddress !== ''){
			
		 showLoader('address_submit_cls','class'); 
		 
		 /*var urlTxt = 'outlets/findZoneBasedLatLng?app_id=' + appId + "&availability_id=" + availability + "&outlet_id=" + outletIdTxt +"&lang_code=" + languageCd + "&latitudeVal=" + tempGmapLat+"&longitudeVal=" + tempGmapLng;*/
		 
		 var urlTxt = 'outlets/findZoneBasedLatLng?app_id=' + appId + "&availability_id=" + availability + "&postalcode_basedoutlet=yes&lang_code=" + languageCd + "&latitudeVal=" + tempGmapLat+"&longitudeVal=" + tempGmapLng;
		 
		
		  axios.get(apiUrlV2 + urlTxt)
		  .then(res => {
			
			hideLoader('address_submit_cls','class');
			
			var deliveryInfo = [];
			/* Success response */
			if (res.data.status === "ok") {
			  $.magnificPopup.close();

			  cookie.save('outletchosen', availability);

			  var orderDeliveryAddress = tempGmapAddress;
			  var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", "+lang.common.orderhandledtext + " " + res.data.result_set.outlet_delivery_timing + " "+lang.common.minutestext;
			  
			  deliveryInfo['orderZoneId'] = res.data.result_set.zone_id;
			  deliveryInfo['orderOutletId'] = res.data.result_set.outlet_id;
			  deliveryInfo['orderOutletName'] = stripslashes(res.data.result_set.outlet_name);
			  deliveryInfo['orderPostalCode'] = '';
			  deliveryInfo['orderTAT'] = res.data.result_set.outlet_delivery_timing;
			  deliveryInfo['orderDeliveryAddress'] = orderDeliveryAddress;
			  deliveryInfo['orderHandled'] = orderHandled;
			  deliveryInfo['defaultAvilablityId'] = availability;
			  
			  var orderHandledText = res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", "+lang.common.countrylable;
			  
			  deliveryInfo['orderHandledByText'] = orderHandledText;
			  
			  this.setState({deliveryInfo: deliveryInfo, seletedAvilablityId: availability, seletedOutletId: res.data.result_set.outlet_id, order_tat_time: res.data.result_set.outlet_delivery_timing, getDateTimeFlg: 'yes', orderHandled: orderHandled, orderDeliveryAddress: orderDeliveryAddress + " "+lang.common.countrylable });

			  removeOrderDateTime();
			  removePromoCkValue();

			  $.magnificPopup.open({
				items: {
				  src: '#awesome-popup'
				},
				type: 'inline'
			  });
			  
			}

			/* Error response */
			if (res.data.status === "error") {
			  this.setState({deliveryInfo: deliveryInfo});	
			  $.magnificPopup.close();
			  $.magnificPopup.open({
				items: {
				  src: '#outlet-error-popup'
				},
				type: 'inline'
			  });
			  
			}
			

		  });	
			
		} else {
		  $('#delevery-postcode-popup').find(".gmap_addrr_error").html('<span class="error"> '+lang.takeawaypopup.searchoutleterror_map+'</span>');
		}
    }
	
	selectDlyOutletBestGmap(availability) {
		var tempGmapLat = this.state.temp_gmap_lat;
		var tempGmapLng = this.state.temp_gmap_lng;
		var tempGmapAddress = this.state.temp_gmap_address;
		if(tempGmapLat !== '' && tempGmapLng !== '' && tempGmapAddress !== ''){
			
		 showLoader('address_submit_cls','class'); 
		
		  axios.get(apiUrl + 'outlets/findOutletBasedLatLng?skip_timing=Yes&app_id=' + appId + "&availability_id=" + availability +"&lang_code=" + languageCd + "&latitudeVal=" + tempGmapLat+"&longitudeVal=" + tempGmapLng)
		  .then(res => {
			
			hideLoader('address_submit_cls','class');
			
			/* Success response */
			if (res.data.status === "ok") {
			  $.magnificPopup.close();

			  cookie.save('outletchosen', availability);

			  var orderDeliveryAddress = tempGmapAddress;
			  var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", "+lang.common.orderhandledtext + " " + res.data.result_set.outlet_delivery_timing + " "+lang.common.minutestext;
			  this.setState({seletedAvilablityId: availability, seletedOutletId: res.data.result_set.outlet_id, order_tat_time: res.data.result_set.outlet_delivery_timing, getDateTimeFlg: 'yes', orderHandled: orderHandled, orderDeliveryAddress: orderDeliveryAddress + " "+lang.common.countrylable });
			  
			  cookie.save('orderOutletId', res.data.result_set.outlet_id);
			  cookie.save('orderOutletName', stripslashes(res.data.result_set.outlet_name));
			  cookie.save('orderPostalCode', '');
			  cookie.save('orderTAT', res.data.result_set.outlet_delivery_timing);
			  cookie.save('orderDeliveryAddress', orderDeliveryAddress);
			  cookie.save('orderHandled', orderHandled);
			  cookie.save('defaultAvilablityId', availability);

			  var orderHandledText = res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", "+lang.common.countrylable;
			  cookie.save('orderHandledByText', orderHandledText);

			  removeOrderDateTime();
			  removePromoCkValue();

			  $.magnificPopup.open({
				items: {
				  src: '#awesome-popup'
				},
				type: 'inline'
			  });
			  
			}

			/* Error response */
			if (res.data.status === "error") {
			  $.magnificPopup.close();
			  $.magnificPopup.open({
				items: {
				  src: '#outlet-error-popup'
				},
				type: 'inline'
			  });
			  
			}
			

		  });	
			
		} else {
		  $('.delivery_outletpoup').find(".gmap_basedaddrr_error").html('<span class="error"> '+lang.takeawaypopup.searchoutleterror_map+'</span>');
		}
    }
	
	handleKeyPress = (event) => {
    
		var value = event.target.value.toLowerCase(),         
		 matches = this.state.pickupOutletsList.filter(function (item) {
			return item.outlet_address_line1.toLowerCase().indexOf(value) !== -1 || item.outlet_postal_code.substring(0, value.length).toLowerCase() === value || item.outlet_name.substring(0, value.length).toLowerCase() === value;
		});
		
		this.setState({pickupOutlets:matches});
		
	}
	
	/* load outlets  */
	loadOutletsList() {
		if (Object.keys(this.state.pickupOutlets).length > 0) {
		  return this.state.pickupOutlets.map((loaddata, index) =>
		  <li key={index} className={this.activeOutlet(loaddata.outlet_id)} ><a href="javascript:void(0);" onClick={this.pickOutlet.bind(this,loaddata)}>{stripslashes(loaddata.outlet_name)} <span><b className="add_line_bold">{loaddata.outlet_address_line1}</b>, {this.showUnitNum(loaddata.outlet_unit_number1,loaddata.outlet_unit_number2)} {lang.common.countrylable} {loaddata.outlet_postal_code}</span></a></li>
			
		  );
		}else{
		  return <li   ><a>{lang.takeawaypopup.nooutletfnd}</a></li>
		}
	}
	
	activeOutlet(outletID) {
		var seletedOutletId = this.state.seletedOutletId;
		var pickupInfo = this.state.pickupInfo;
		var actTxt = ((parseInt(seletedOutletId)===parseInt(outletID)) && (Object.keys(pickupInfo).length > 0)) ? "active" : '';
		return actTxt;
	}
	
	handleKeyPressDly = (event) => {
    
		var value = event.target.value.toLowerCase(),         
		 matches = this.state.deliveryOutletsList.filter(function (item) {
			return item.outlet_address_line1.toLowerCase().indexOf(value) !== -1 || item.outlet_postal_code.substring(0, value.length).toLowerCase() === value || stripslashes(item.outlet_name).substring(0, value.length).toLowerCase() === value;
		});
		
		this.setState({deliveryOutlets:matches});
		
	}
	
	/* load delivery outlets  */
	loadDeliveryOutletsList() {
		if (Object.keys(this.state.deliveryOutlets).length > 0) {
		  return this.state.deliveryOutlets.map((loaddata, index) =>
		  <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)} ><a href="javascript:void(0);" onClick={this.deliveryOutlet.bind(this,loaddata)}>{stripslashes(loaddata.outlet_name)} <span>{loaddata.outlet_address_line1}, {this.showUnitNum(loaddata.outlet_unit_number1,loaddata.outlet_unit_number2)} {lang.common.countrylable} {loaddata.outlet_postal_code}</span></a></li>
			
		  );
		}else{
		  return <li   ><a>{lang.takeawaypopup.nooutletfnd}</a></li>
		}
	}
	
	
	activeDlyOutlet(outletID) {
		
		var orderOutletId = (this.state.delivery_outlet_id !== '') ? this.state.delivery_outlet_id :cookie.load('orderOutletId');
		
		return (orderOutletId === outletID?"active":'');
	}
	
	
	showUnitNum(unit1,unit2) {
		unit1 = (typeof unit1 !== 'undefined') ? unit1 : '';
		unit2 = (typeof unit2 !== 'undefined') ? unit2 : '';
		
		if(unit1 !== '') {
			var unitNo = (unit2 !== '') ? unit1+' - '+unit2 : unit1;
		} else {
			var unitNo = unit2;
		}
		
		return (unitNo !== '')?'#'+unitNo+',':'';
	}
	
	/* pick outlet */
	pickOutlet(loaddata){

		  var orderHandled = stripslashes(loaddata.outlet_name)+" "+loaddata.outlet_address_line1+" "+loaddata.outlet_address_line2+", "+lang.common.countrylable+" "+loaddata.outlet_postal_code;
		  
		  var pickupInfo = [];
			  pickupInfo['orderOutletId'] = loaddata.outlet_id;
			  pickupInfo['orderOutletName'] = stripslashes(loaddata.outlet_name);
			  pickupInfo['orderPostalCode'] = loaddata.outlet_postal_code;
			  pickupInfo['orderTAT'] = loaddata.outlet_pickup_tat;
			  pickupInfo['orderHandled'] = orderHandled;
			  pickupInfo['orderHandledByText'] = orderHandled;
			  pickupInfo['defaultAvilablityId'] = pickupId;	
			  pickupInfo['pickupHavingHere'] = loaddata.outlet_pickup_having_here;
			  pickupInfo['pickupToGo'] = loaddata.outlet_pickup_to_go;
		
		  this.setState({pickupInfo: pickupInfo, seletedAvilablityId: pickupId, seletedOutletId: loaddata.outlet_id,  order_tat_time: loaddata.outlet_pickup_tat, orderHandled:orderHandled});
		  
	}
	
	deliveryOutlet(loaddata){
		$('.delivery_outletpoup').find(".outlet_error").html('');
		this.setState({delivery_outlet_id:loaddata.outlet_id});
	}
	
	 chooseOutletDet(qrcodeOutletId){
		var availability = pickupId;
		var defaultAvilTy = cookie.load('defaultAvilablityId');
		if(defaultAvilTy!==availability){
		  var cartTotalItems = cookie.load('cartTotalItems');
			  cartTotalItems = (cartTotalItems != '' && cartTotalItems != undefined) ? parseInt(cartTotalItems) : 0;
		  if(defaultAvilTy === cateringId && cartTotalItems > 0) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  } else if(cookie.load('orderOutletId') != '' && cookie.load('orderOutletId') != undefined) {
			  this.setState({nextavail:availability});
			  $.magnificPopup.open({
				items: {
				  src: '#warning-popup'
				},
				type: 'inline'
			  });
			  return false;
		  }
		}
		
		showLoader('home-main-div','class');
		axios.get(apiUrlV2+'outlets/getAllOutles?app_id='+appId+'&lang_code='+languageCd+"&availability_id="+availability+"&outlet_id="+qrcodeOutletId)
		  .then(res => {
			  hideLoader('home-main-div','class');
			  var errorInfSt = '';
			  /* Success response */
			 if (res.data.status === "ok") {
				 if(Object.keys(res.data.result_set).length > 0) {
					this.pickOutletData(res.data.result_set);
				 } else {
					 errorInfSt = 'yes';
				 }
			 } else {
				 errorInfSt = 'yes';
			 }
			 
			 if(errorInfSt === 'yes') {
				 $.magnificPopup.open({
					items: {
					  src: '#warning-popup'
					},
					type: 'inline'
				  });
				  return false;
			 }
		});
	  

	  }
	  
	  pickOutletData(loaddataArr){
		    var loaddata = loaddataArr[0];
		  
		    var orderHandled = stripslashes(loaddata.outlet_name)+" "+loaddata.outlet_address_line1+" "+loaddata.outlet_address_line2+", "+lang.common.countrylable+" "+loaddata.outlet_postal_code;
		  
			var orderDateTime = new Date();
			var OrderHours = orderDateTime.getHours();
			var OrderMunts = orderDateTime.getMinutes();
			var OrderSecnd = orderDateTime.getSeconds();
		
			var deliveryDate = format(orderDateTime, "dd/MM/yyyy");
			var deliveryTime = this.convPad(OrderHours)+":"+this.convPad(OrderMunts)+":"+this.convPad(OrderSecnd);			
			
			cookie.save('orderDateTime', orderDateTime);
			cookie.save('deliveryDate', deliveryDate);
			cookie.save('deliveryTime', deliveryTime);
			
			cookie.save('orderOutletId', loaddata.outlet_id);
			cookie.save('orderOutletName', stripslashes(loaddata.outlet_name));
			cookie.save('orderPostalCode', loaddata.outlet_postal_code);
			cookie.save('orderTAT', loaddata.outlet_pickup_tat);
			cookie.save('orderHandled', orderHandled);
			cookie.save('defaultAvilablityId', pickupId);
			cookie.save('orderHandledByText', orderHandled);
			
			cookie.save('outletchosen', pickupId);
			this.props.history.push('/products');
		  
	}
	
	componentDidMount() {
		
		$('.test-popup-link').magnificPopup({
		  type: 'image',
                  showCloseBtn:true,
                  verticalFit: true,
		   callbacks: {
            		 change: function() {
			  this.wrap.addClass('awardpopup');
		       	 },
         		}
		  // other options
		});

		this.props.getMenuData('Main-menu');
		if ($(".open-popup-link").length > 0) {
		  $('.open-popup-link').magnificPopup({
			type: 'inline',
			midClick: true 
		  });
		}
		
		if ($(".trigger_login").length > 0) {
			$('.trigger_login').magnificPopup({
				type: 'inline',
				midClick: true 
			});
        }
		
		/* Input lable animation */
		if ($(".input-focus").length > 0) {
			$('.input-focus').focus(function() {
				$(this).parents('.focus-out').addClass('focused');
			});
			$('.input-focus').blur(function() {
				var inputValue = $(this).val();
				if (inputValue == "") {
					$(this).removeClass('filled');
					$(this).parents('.focus-out').removeClass('focused');
				} else {
					$(this).addClass('filled');
				}
			});
		}
		
		if ($(".hsearch_trigger").length > 0) {
		  $('.hsearch_trigger').click(function (e) {
			e.stopPropagation();
			$('.search_result').hide();
			$(this).toggleClass('active');
			$('.hsearch_sec').toggleClass('open');
			$('.hsearch_sec .form-control').focus();
		  });
		  $(document).click(function (e) {
			if (!$(e.target).is('.hsearch_trigger, .hsearch_sec, .hsearch_sec * ')) {
			  if ($('.hsearch_sec').is(":visible")) {
				$('.hsearch_sec').removeClass('open');
				$('.hsearch_trigger').removeClass('active');
				$('.hsearch_sec .form-control').blur();
			  }
			}
		  });
		}
		
		if ($(".mobile_mainacc_menutrigger").length > 0) {
            $(".mobile_mainacc_menutrigger").click(function(e){
                e.stopPropagation();            
                if ($('.mobile_mainacc_menulist').is(":visible")) {
                    $('.mobile_mainacc_menulist').hide();
                }
                else{
                    $('.mobile_mainacc_menulist').show();            
                }
            });
            $(document).click(function(e) {
                if (!$(e.target).is('.mobile_account_item, .mobile_account_item * ')) {
                    if ($('.mobile_mainacc_menulist').is(":visible")) {
                        $('.mobile_mainacc_menulist').hide();
                    }
                }
            });
        }
		
		/*NEW MENU SECTION*/
        if ($(".mobile_account_menu_click").length > 0) {
            $(".mobile_account_menu_click").click(function (e) {
                e.stopPropagation();
                $('.mobile_account_menu').slideToggle();
            });
            $(document).click(function (e) {
                if (!$(e.target).is('.arrow_myaccount, .mobile_account_menu_col')) {
                    if ($('.mobile_account_menu').is(":visible")) {
                        /*$('.mobile_account_menu').slideToggle();*/
                        $('.mobile_account_menu').hide();
                    }
                }
            });
        }
		
		/*LANGUAGE MBL SECTION*/
        if ($(".mbl_lang_default").length > 0) {
            $(".mbl_lang_default").click(function (e) {
                e.stopPropagation();
                $('.mbl_lang_list_div').slideToggle();
            });
            $(document).click(function (e) {
                if (!$(e.target).is('.mbl_lang_default_ahref')) {
                    if ($('.mbl_lang_list_div').is(":visible")) {
                        $('.mbl_lang_list_div').slideToggle();
                    }
                }
            });
        }
		
		/*LANGUAGE SECTION*/
        if ($(".lang_default").length > 0) {
            $(".lang_default").click(function (e) {
                e.stopPropagation();
                $('.lang_list_div').slideToggle();
            });
            $(document).click(function (e) {
                if (!$(e.target).is('.lang_default_ahref')) {
                    if ($('.lang_list_div').is(":visible")) {
                        $('.lang_list_div').slideToggle();
                    }
                }
            });
        }
		
		/* Mobile header menu */
        $('.hcategory_trigger').click(function () {
            $(this).toggleClass('active');
            $('.hcategory_menu').toggleClass('open');
        });

        $(document).click(function (e) {
            if (!$(e.target).is('.hcategory_trigger')) {
                if ($('.hcategory_menu').hasClass("open")) {
                    $('.hcategory_menu').removeClass('open');
                    $('.hcategory_trigger').removeClass('active');
                }
            }
        });
		
		
		$('body').addClass('body-selected-lang-'+languageCd);
		
		/*this.getOptions.bind(this,"mill");*/
		this.getSearchProductList();
	}
	
	componentDidUpdate() {
		
	}
	
	
	getSearchProductList() {
		
		var orderOutletIdtext = cookie.load('orderOutletId');
		var addquery_txt = '';
		if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== '') {
		  addquery_txt = "&outletId=" + orderOutletIdtext;
		}
		var searchResult = [];
		return axios.get(apiUrlV2 + 'products/search_products?app_id=' + appId +'&lang_code='+languageCd + "&status=A&availability=" + cookie.load('defaultAvilablityId') + addquery_txt).then((response) => {
		  if (response.data.status === "ok") {
			var resultData = response.data.result_set;
			    for (var key in resultData) {
					var subcatListArr = (!('product_list' in resultData[key])) ? Array() : resultData[key].product_list;
					
					if(Object.keys(subcatListArr).length > 0) {
					  if(Object.keys(subcatListArr[0]).length > 0) {
						var subCatArr = (!('subcategorie' in subcatListArr[0][0])) ? Array() : subcatListArr[0][0].subcategorie;
						for (var sctkey in subCatArr) {
							var productsArr = (!('products' in subCatArr[sctkey])) ? Array() : subCatArr[sctkey].products;
							for (var prokey in productsArr) {
								var proNameTxt = getAliasName(productsArr[prokey].product_alias, productsArr[prokey].product_name);
								searchResult.push({ cate_slug: subCatArr[sctkey].pro_cate_slug, subcate_slug: subCatArr[sctkey].pro_subcate_slug, value: productsArr[prokey].product_slug, label: stripslashes(proNameTxt)})
							}
						}
					  }
					}
				}
				
		  }
		  
		  this.setState({searchProResult: searchResult, selectedProResult: searchResult});
		});
		
	}
	
	searchProKeyPress = (event) => {
    
		$('.search_result').show();
		
		var value = event.target.value.toLowerCase(),         
		 matches = this.state.searchProResult.filter(function (item) {
			return item.label.substring(0, value.length).toLowerCase() === value;
		});
		
		$('#clearSearch').show();
		if(value === '') {
			$('#clearSearch').hide();
		}
		
		this.setState({selectedProResult:matches});
		
	}
	
	/* load product search result  */
	loadProSearchList() {
		if (Object.keys(this.state.selectedProResult).length > 0) {
		  return this.state.selectedProResult.map((loaddata, index) =>
		  <li key={index}><Link to={"/products/"+loaddata.cate_slug+"/"+loaddata.subcate_slug+"/"+loaddata.value} title="Product Details" >{loaddata.label}</Link></li>
			
		  );
		}else{
		  return <li className="no-pro-found">{lang.navigation.searchnoresult}</li>
		}
	}
	
	clearSearchFun() {
		 var emtytxt = '';
		 $('#productsearch').val(emtytxt);
		 this.setState({selectedProResult:this.state.searchProResult});
	}
	
	getOptions = (input) => {
		
		var searchProResult = this.state.searchProResult;
		var searchResult = [];
		/*if (Object.keys(searchProResult).length > 0) {*/
			 /*searchProResult.map((loadData) =>
			  searchResult.push({ value: loadData.value, label: loadData.label })
			 );*/
			 searchResult.push({ value: 'wqewrr', label: 'fish cury' });
			 searchResult.push({ value: 'werew3', label: 'fish cury2' });
			 console.log('wlll');
		     console.log(searchResult);
		     console.log('input');
		     console.log(input);
			 return { options: searchResult };
		/*}*/
    };
	
	ViewProducts(event) {
		var productSlug = event.value;
		this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
    }
	
	showOutletNameText() {
		  var orderOutletId = cookie.load('orderOutletId');
		  if(orderOutletId !== '' && orderOutletId !== undefined){
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletName = cookie.load('orderOutletName');
		  var showOutlet = "";
		  if(availability === deliveryId) {
			  showOutlet = "Delivery Outlet : ";
		  } else if(availability === pickupId) {
			  showOutlet = "Pickup From : ";
		  }
		  showOutlet = showOutlet+orderOutletName;
		  
		  var currenturlTmp = window.location.href;
		  var substringTmp = "thankyou";
		  
		  var qrcodeOutletIdVl = (cookie.load('qrcodeOutlet') != '' && cookie.load('qrcodeOutlet') != undefined) ? cookie.load('qrcodeOutlet') : localStorage.getItem('qrcodeOutlet');
		  var orderFromQrcodescannerFlg = (cookie.load('defaultAvilablityId') === pickupId && cookie.load('orderOutletId') === qrcodeOutletIdVl) ? 'yes' : 'no';
		  
		  if(currenturlTmp.includes(substringTmp)) {
			  orderFromQrcodescannerFlg = 'yes';
		  }
		  
		  return (<div className="outletinf-show-div">
		  {showOutlet.toUpperCase()}
		  {(orderFromQrcodescannerFlg === 'no') && <a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, availability)} className="chang-outlet-link"> ( Change )</a>}
		  </div>);
		  }
	  }
	
	checkAblBtn() {
		
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletId = cookie.load('orderOutletId');
		  var actText = lang.navigation.orderNow;
		  var deliveryClsTxt = 'hordertype_btn lst_btn';
		  var pickupClsTxt = 'hordertype_btn lst_btn';
		  
		  if(availability === deliveryId && orderOutletId !== '' && orderOutletId !== undefined){
			  deliveryClsTxt = deliveryClsTxt + ' active_avl';
		  }
		  
		  if(availability === pickupId && orderOutletId !== '' && orderOutletId !== undefined){
			  pickupClsTxt = pickupClsTxt + ' active_avl';
		  }
		  
		  
		  return (<span>
		  <a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, deliveryId)} className={deliveryClsTxt}>{lang.availability.delivery}</a> | 
		  <a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, pickupId)} className={pickupClsTxt}>{lang.availability.takeaway}</a>
		  </span>);
		  
		  
	  }
	  
	  checkActiveDivHd(avlType) {
		  var clsTxt = "ordericon_link ";
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletId = cookie.load('orderOutletId');
		  if(availability==avlType && orderOutletId !== '' && orderOutletId !== undefined){
			  clsTxt += 'active';
		  } else if(availability==avlType && (avlType === cateringId || avlType === reservationId)){
			  clsTxt += 'active';
		  } 
		  return clsTxt; 
	  }
	  
	  checkActiveDivMbl(avlType) {
		  var clsTxt = "";
		  var availability = cookie.load('defaultAvilablityId');
		  var orderOutletId = cookie.load('orderOutletId');
		  if(availability==avlType && orderOutletId !== '' && orderOutletId !== undefined){
			  clsTxt += 'active';
		  } else if(availability==avlType && (avlType === cateringId || avlType === reservationId)){
			  clsTxt += 'active';
		  } 
		  return clsTxt; 
	  }
	  
	  myAccountAction() {
		  
		  var currenturl = window.location.href;
          var substringtxt = "myaccount";
		  
		  if(currenturl.includes(substringtxt) !== true) {
			  return (<Link to={"/myaccount"} title="My Account"> {lang.navigation.myaccount} <span className="mobile_mainacc_menutrigger"></span></Link>)
		  } else {
			  return (<a className="" href="javascript:void(0);"> {lang.navigation.myaccount} <span className="mobile_mainacc_menutrigger"></span></a>)
		  }
	  }
	  
	  changPostalValue(type,pstVl) {
		  if(type === 1) {
			$("#postalcode").val(pstVl);
		  } else {
			$("#postalcode1").val(pstVl); 
		  }
	  }
	  
	  userAddressList(typeTxt) {
		  if (this.state.secondaryaddresslist.length > 0) {
				var addListTxt = this.state.secondaryaddresslist.map((addr, index) =>
				    <div className="address_linfo" key={typeTxt+'-'+index}>
					   <div className="custom_radio">
						<input type="radio" name={"address_chk"+typeTxt} value={addr.postal_code} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, addr.postal_code)} />
						<span>{(addressFormat(addr.unit_code, addr.unit_code2, addr.address, addr.country, addr.postal_code))}</span>
						</div>		
					</div>);
					
				  return (<div>
						   {addListTxt}
						   <div className="address_linfo" key={typeTxt+'dfl'}>
							   <div className="custom_radio">
								<input type="radio" name={"address_chk"+typeTxt} value='' defaultChecked={true} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, '')} />
								<span>{lang.deliverypopup.newaddresslbl}</span>
								</div>		
							</div> 	
						  </div>);	
					
			} else {
				return ''
			}
		  
	  }
	  
	  languageList() {
		  var langArr = Array();
		  if(Object.keys(this.props.globalsettings).length > 0) {
			  if(Object.keys(this.props.globalsettings[0].result_set.client_language_list).length > 0) {
				  langArr = this.props.globalsettings[0].result_set.client_language_list.languages;
			  }
			  var clientCurrency = '$';
			  var clientCurrencyName = 'SGD';
			  var clientCountry = 'Singapore';
			  var client_currency = this.props.globalsettings[0].result_set.client_currency;
			  var client_currency_name = this.props.globalsettings[0].result_set.client_currency_name;
			  var client_country = this.props.globalsettings[0].result_set.client_country;
			  if(client_currency !== '' && client_currency !== null && client_currency !== 'S$') {
				  clientCurrency = client_currency;
			  }
			  if(client_currency_name !== '' && client_currency_name !== null) {
				  clientCurrencyName = client_currency_name;
			  }
			  if(client_country !== '' && client_country !== null) {
				  clientCountry = client_country;
			  }
			  cookie.save('clientCurrency',clientCurrency);
			  cookie.save('clientCurrencyName',clientCurrencyName);
			  cookie.save('clientCountry',clientCountry);
		  }
		  
		  if(Object.keys(langArr).length > 1) {
			  
			  var langLiHtml = langArr.map((langitem, index) => {
								 if(langitem.language_code !== languageCd) {
									return (<li key={index} onClick={this.handleChangeLang.bind(this, langitem)} className={(langitem.language_code === languageCd)?"lang-active":""}>
									<span>{langitem.language_name}</span>
									</li>)
								  }
								}); 
			  
			  return (<ul>{langLiHtml}</ul>);
		  } else {
			  return '';
		  }
	  }
	  
	  handleChangeLang(langitem) {
		  if(Object.keys(langitem).length > 0) {
			  var st_language = defaultLang;
			  var st_language_name = defaultLangName;
			  var st_language_flag = defaultLangFlag;
			  if(availLangFiles.indexOf(langitem.language_code) !== -1) {
				st_language = langitem.language_code;
				st_language_name = langitem.language_name;
				/*st_language_flag = langImg+langitem.language_flimage;*/  
			  }
			  
			  this.setState({nextavail:'languagechange',st_language:st_language,st_language_name:st_language_name,st_language_flag:st_language_flag});
			  
			  var cartTotalItems = cookie.load('cartTotalItems');
			  cartTotalItems = (cartTotalItems != '' && cartTotalItems != undefined) ? parseInt(cartTotalItems) : 0;
			  
			  var orderOutletId = cookie.load('orderOutletId');
			  if((orderOutletId != '' && orderOutletId != undefined) || (cartTotalItems > 0)) {
			  
				  $.magnificPopup.open({
					items: {
					  src: '#warning-popup'
					},
					type: 'inline'
				  });
			  
			  } else {
				  
					cookie.save("language",st_language);
					cookie.save("language_name",st_language_name);
					cookie.save("language_flag",st_language_flag);
					
					window.location.replace('/');
				  
			  }
		  }
	  }
	  
	  changeCountryFun(swpcountry) {
		  
		  if(CountryTxt !== swpcountry) {
			  var siteUrltxt = (swpcountry === 'Singapore') ? siteurlSingapore : siteurlIndonesia;
			  window.location.href = siteUrltxt;
		  }
		  
	  }
	  
	  showPlaceDetails(place) {
		var tempGmapLat = '', tempGmapLng = '', tempGmapAddress = '';
		if(Object.keys(place).length > 1) {
			tempGmapLat = place.geometry.location.lat();
			tempGmapLng = place.geometry.location.lng();
			tempGmapAddress = place.formatted_address;
			
			/*$('.delivery_outletpoup').find(".gmap_basedaddrr_error").html('');*/
			$('#delevery-postcode-popup').find(".gmap_basedaddrr_error").html('');
		}
		
		this.setState({temp_gmap_lat:tempGmapLat,temp_gmap_lng:tempGmapLng,temp_gmap_address:tempGmapAddress});
	  }
	  
	  setdateTimeFlg = (field, value) => {
		  if(field == 'tmflg') {
				this.setState({getDateTimeFlg:value});
		  }	
		  else if(field == 'ordDate') {
				var ordTime = '';
				$('.ordrdatetime_error').html('');
				this.setState({seleted_ord_date:value,seleted_ord_time:ordTime});
		  }		
		  else if(field == 'ordTime') {
			    $('.ordrdatetime_error').html('');
				this.setState({seleted_ord_time:value});
		  }
		  else if(field == 'triggerErrorPopup') {
			    $.magnificPopup.open({
					items: {
					  src: '#outlet-error-popup'
					},
					type: 'inline'
			    });
		  }	  
	  }
	
    /* ViewProducts */

    render() {
		
		var currenturl = window.location.href;
        var substring = "products";
        var isCheckout = "checkout";
		
		var showCatryName = (typeof this.props.showCatryName !== "undefined") ? this.props.showCatryName : 'Category Name';
		
		var defaultAvilTyId = cookie.load('defaultAvilablityId');
		
        return (
			   <header>
			   
					{/*<div className="mbl-lang-section">
					  <div className="container">
					  
						<div className="mbl_lang_slt_main">
						  <div className="mbl_lang_slt_sub">
							<div className="mbl_lang_default">
								<a className="mbl_lang_default_ahref" href='javascript:;' >
									<span>{languageNm}</span>
								</a>
							</div>
							<div className="mbl_lang_list_div">
								{this.languageList()}
							</div>
						  </div>
						  
						  <div className="mbl_location_slt_main" onClick={this.changeCountrypopup.bind(this)}>
								{lang.countrypopup.selected}
						  </div>
						</div>
						
					  </div>
					</div>*/}
			   
					<div className="header-top-cls">
					  <div className="container">
						<div className="logo-lang-section">
						
						   <div className="lang_slt_main">
						   {/*<div className="lang_slt_sub">
								<div className="lang_default">
									<a className="lang_default_ahref" href='javascript:;' >
										<span>{languageNm}</span>
									</a>
								</div>
								<div className="lang_list_div">
									{this.languageList()}
								</div>
							  </div>
							  
							  <div className="location_slt_main" onClick={this.changeCountrypopup.bind(this)}>
									{lang.countrypopup.selected}
						   </div>*/}
							  
							</div>	
					
							<div className="logo">
								<Link to={"/"} title="Makisan">
									<img src={mainLogo} alt="Logo"/>
								</Link>
							</div>
						
						   <div className="hmenu-login-section">
							<ul className="hmenu-login-act">
							{!cookie.load("UserId") && <li className="hsign_sec">
							<a href="#login-popup" data-effect="mfp-zoom-in" className="open-popup-link htico_sign" title="Login"><span>{lang.navigation.login}</span></a>
							</li>}
							
							{cookie.load("UserId") &&  <li className="hsign_sec">
								<Link className="htico_sign" to="/myaccount" title="My Account"><i /> <span>{lang.navigation.myaccount}</span></Link><span>|</span>
								<Link className="htico_sign" to="/logout" title="Logout"><i /> <span>{lang.navigation.logout}</span></Link>
							</li>}
							
							<li className="hordertype_sec">
							{this.checkAblBtn()}
							</li>
							
							</ul>
							
							{this.showOutletNameText()}
						   </div>
							
						</div>
					  </div>	
					</div>
					
                   
					{/* header-bottom - start */}
					<div className="header-bottom">
					  <div className="container">
					   <div className="menu-cart-section">
					
						<div className="haction_sec">
							<div className="header_bot">
									<div className="hmenu_sec">
									
									 <div className="menu_icon trigger_menu">
                                        <span className="icon-bar icon-bar1"></span>
                                        <span className="icon-bar icon-bar2"></span>
                                        <span className="icon-bar icon-bar3"></span>
                                     </div>
								
									<ul className="hmenu_list desktop_hmenu_list">
										{this.state.menudata && this.listMainNavigation()}
									</ul>
									 {/* mobile menu - start */}
									 <ul className="hmenu_list mobile_hmenu_list">
										{this.state.menudata && this.listMainNavigation()}
									 </ul>
									 {/* mobile menu - end */}
								
								</div>
								{/*<div className="user-profile mobile_user_profile">
								   <a className="user-profile-action" href="javascript:void(0);"><img src={userProfile} alt="User Profile"/></a>
								   {!cookie.load("UserId") && <ul className="user-profile-list">
										<li> <a href="#login-popup" data-effect="mfp-zoom-in" className="open-popup-link htico_sign" title="Login"><span>Login</span></a> </li>
										<li> <a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link" title="Sign up"> <span>Register</span></a> </li>
								   </ul>}
								   {cookie.load("UserId") && <ul className="user-profile-list">
										<li> <Link className="htico_sign" to="/myaccount" title="My Account"><i /> <span>My Account</span></Link> </li>
										<li> <Link className="htico_sign" to="/logout" title="Logout"><i /> <span>Logout</span></Link> </li>
								   </ul>}
								  </div> */}
							</div>
						</div>
						
						
						<ul className="hmenu_actions">
							
							<li className="htico_search">
							
							    {(defaultAvilTyId === cateringId || defaultAvilTyId === reservationId) && <a href="javascript:void(0)" data-effect="mfp-zoom-in" className="hsearch_trigger_dis" title="Search">&nbsp;</a>}
							
							    {(cookie.load('orderOutletId') !== undefined && defaultAvilTyId !== cateringId && defaultAvilTyId !== reservationId) && <a href="javascript:void(0)" className="hsearch_trigger" title="Search"><i /></a>}
								
								{(cookie.load('orderOutletId') === undefined && defaultAvilTyId !== cateringId && defaultAvilTyId !== reservationId) && <a href="#order-popup" data-effect="mfp-zoom-in" className="hsearch_trigger open-popup-link" title="Search"><i /></a>}
								
								<div className="hsearch_sec">
								
								    <div className="input-sec">
									<input type="text" name="searchkey" id="productsearch" placeholder={lang.navigation.searchboxlbl} onKeyUp={this.searchProKeyPress} />
									
									 <a href="javascript:void(0);" id="clearSearch" onClick={this.clearSearchFun.bind(this)} className="search_text_clear">
										 X
									 </a>
									</div>
									
									<ul className="search_result" id="search_result">
									{this.loadProSearchList()}
									</ul>
									
									
									{/*<Select name="form-field-name" placeholder="Go ahead search for a product..." noResultsText="No Products Found" onChange={this.ViewProducts.bind(this)} options={this.state.searchProResult} />*/}
									  
									
								</div>
								
							</li>
							
							<CartSideBar {...this.props} headerState={this.state} prpSateValChange={this.props.sateValChange} />
						</ul>
						
					  </div>
					  
					   <div className="show-outlettext-mobile">
						{this.showOutletNameText()}
					   </div>
					 </div>
					</div>
					{/* header-bottom - end */}	
				 
				   
				
				{/* mobile top order now button */}
				{/*<div className="mobile-order-nowbtn">
				<Link className="button" to={"/products"} title="Order Now">Order Now</Link>
				</div>*/}
				
				{/* mobile_accountdel_body - start */}
				<div className="mobile_accountdel_body mobile-order-nowbtn">
					<div className="mobile_accountdel_row">
					
						<div className="mobile_accountdel_col">

							{!cookie.load("UserId") && <div className="mobile_account_item"><div className="mobile_account_item_login"><a className="open-popup-link" href="#login-popup" data-effect="mfp-zoom-in"  ><i></i>{lang.navigation.login}</a> <span className="myaccount_separate"> </span>
								<a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link signup_btn"  ><i></i>{lang.navigation.signup}</a> </div> </div>}
								
							{cookie.load("UserId") && <div className="mobile_account_item">
							
							{this.myAccountAction()}
							
							<div className="mobile_mainacc_menulist">
								<ul>
									<li><a href="#change-password-popup" className="open-popup-link">{lang.navigation.changepassword}</a></li>
									<li><Link to={"/logout"} title="Logout">{lang.navigation.logout}</Link></li>
								</ul>
							</div>
							
							</div>}

						</div>
						
						<div className="mobile_accountdel_col">
							<a className="mobile_account_menu_click" href="javascript:void(0);">{this.chooseAvailabilityMbl()} <i className="arrow_myaccount"></i></a>
							
							<div className="mobile_account_menu">
								<ul className="mobile_account_delivery_item">
									<li className={this.checkActiveDivMbl(deliveryId)}>
									<a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, deliveryId)} data-effect="mfp-zoom-in" className="open-popup-link" >{lang.availability.delivery}</a></li>
									<li className={this.checkActiveDivMbl(pickupId)}>
									<a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, pickupId)} data-effect="mfp-zoom-in" className="open-popup-link">{lang.availability.takeaway}</a></li>
									{/*<li className={this.checkActiveDivMbl(cateringId)}>
									<a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, cateringId)} data-effect="mfp-zoom-in" className="open-popup-link">Catering</a></li>
									<li className={this.checkActiveDivMbl(reservationId)}>
									<a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, reservationId)} data-effect="mfp-zoom-in" className="open-popup-link">Reservation</a></li>*/}
								</ul>
							</div>
						</div>
						
					</div>
				</div>
				{/* mobile_accountdel_body - end */}
				
				
				{currenturl.includes(substring) && <div className="hcategory_sec">
				    <div className="trigger-parent">
					<a href="javascript:;" className="hcategory_selected_text">{showCatryName}</a>
					<span className="hcategory_trigger">View More <img src={arrowimg} /></span>
					</div>
					<div className="hcategory_menu ">
						<MenuNavigationmob />
					</div>
				</div>}
				
				
				<div className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide" id="jquery-success-msg" style={{display:'none'}}> <a href="javascript:void(0)" type="button" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-success-msg">Nice! Products added to your cart</p> </div>
				
				<div className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide" id="jquery-error-msg" style={{display:'none'}}> <a href="javascript:void(0)" className="custom_close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </a> <p className="jquery-error-msg">Something went wrong</p> </div>
				
				{/* login popup */}

				 <div id="login-popup" className="white-popup mfp-hide popup_sec login-popup">
				 <div className="pop-whole">
					<div className="pop-whole-lhs">
						 <div className="pop-whole-lhs-inner">
							 <h3>{lang.loginpopup.title}</h3>
							 <p>{lang.loginpopup.subtitle}</p>
						 </div>
					 </div>
					 <div className="inside-popup-rhs">
					   <div className="popup-footer">
							 <FacebookLogin
									 appId={fbAppId}
									 fields="name,email,picture,first_name,last_name,birthday,gender"
									 callback={this.responseFacebook}
									 scope="public_profile,email,user_birthday"
									 cssclassName="btn btn-black  fa fa-facebook"
									 redirectUri={baseUrl}
									 icon="fa-facebook"
									 textButton={" "+lang.loginpopup.fbnametext}
							 />
							 <div className="or-seperator"><span>{lang.loginpopup.separator}</span></div>
						 </div>
						 <div className="popup-header textcenter">
							 <h4>{lang.loginpopup.innerlbl}<img src={mainLogo} /></h4>
						 </div>
						 
						 <Login fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleSignin} onInvalid={() => console.log('Form invalid!')} />

						 <div className="guest-checkout-popupdiv textcenter">
						 
						  <div className="pop-whole-mblview">
								 <h3>Dont have an account ?</h3>
								  <a href="#signup-popup" className="open-popup-link"> {lang.loginpopup.createaccountlink}</a>
						 </div>
						 
							<a href="#guest-popup" className="button open-popup-link">{lang.loginpopup.guestloginbtn}</a>
						 </div>
						 
					 </div>
				 </div>
				</div>
				
			   {/* Signup popup */}
				<div id="signup-popup" className="white-popup mfp-hide popup_sec signup-popup">
				 <div className="pop-whole">
					<div className="pop-whole-lhs">
						<div className="pop-whole-lhs-inner">
							<h3>{lang.signuppopup.title}</h3>
							<p>{lang.signuppopup.subtitle}</p>
						</div>
					</div>
					 <div className="inside-popup-rhs">
					 
						<div className="back_click">
							<a href="#login-popup" className="open-popup-link" >{lang.signuppopup.backbtn}</a>
						</div>
					 
					    <div className="popup-footer">
							 <FacebookLogin
								 appId={fbAppId}
								 fields="name,email,picture,first_name,last_name,birthday,gender"
								 callback={this.responseFacebook}
								 scope="public_profile,email,user_birthday"
								 cssclassName="btn btn-black  fa fa-facebook"
								 redirectUri={baseUrl}
								 icon="fa-facebook"
								 textButton={" "+lang.signuppopup.fbnametext}
							 />
							 <div className="or-seperator"><span>{lang.signuppopup.separator}</span></div>
						 </div>
						 <div className="popup-header textcenter">
							 <h4>{lang.signuppopup.innerlbl} <img src={mainLogo} /></h4>
						 </div>
						 <Signup fields={this.state.fieldssignup} onChange={this.fieldChangeSignup} onValid={this.handleSignup} onInvalid={() => console.log('Form invalid!')} />
					 </div>
				 </div>
				</div>
				
				
				{/* Guest Checkout */}
				<div id="guest-popup" className="white-popup mfp-hide popup_sec guest-popup">
					<div className="pop-whole">
						<div className="pop-whole-lhs">
							<div className="pop-whole-lhs-inner">
								<h3>{lang.guestpopup.title}</h3>
								<p>{lang.guestpopup.subtitle}</p>
							</div>
						</div>
						<div className="inside-popup-rhs">
							<div className="back_click">
								<a href="#login-popup" className="open-popup-link" >{lang.guestpopup.backbtn}</a>
							</div>
							<Guestcheckout fields={this.state.fieldscheckout} onChange={this.fieldChangecheckout} onValid={this.handleCheckout} onInvalid={() => console.log('Form invalid!')} />
						</div>
					</div>
				</div>
				

				{/* Forgot Password Popup */}

				<div id="forgot-password-popup" className="white-popup mfp-hide popup_sec login-popup forgot-password-popup">
				 <div className="pop-whole">
				 
				  <div className="pop-whole-lhs">
					 <div className="pop-whole-lhs-inner">
						 <h3>{lang.forgotpasswordpopup.title}</h3>
						 <p>{lang.forgotpasswordpopup.subtitle}</p>
					 </div>
				  </div>
				  
				  <div className="inside-popup-rhs">
					 <div className="popup-header textcenter">
						 <h4><img src={mainLogo} /></h4>
					 </div>	
					 <Forgotpassword fields={this.state.fieldsfgtpassword} onChange={this.fieldforgot} onValid={this.forgotpassword} onInvalid={() => console.log('Form invalid!')} />
				  </div>
				  
				 </div>
				</div>
				
				{/* Country popup - start */}
				  <div id="country-popup" className="white-popup mfp-hide popup_sec order_popup country-chng_popup">
					<div className="order-body">
					  <h2>{lang.countrypopup.title}</h2>
					  <p>&nbsp;</p>
					  <div className="order_delivery_row">
						<div className="order_delivery_col">
						  <ul className="order_delivery_item">
						  
							<li>
							  <a href="javascript:;" className={(CountryTxt === 'Singapore')?'active':''} onClick={this.changeCountryFun.bind(this, 'Singapore')}>
								<div className="header-ordernow-single-img">
									<img src={singaporeImg} />
									<h3>{lang.countrypopup.countrylable1}</h3>
								</div>
							  </a>
							</li>
							
							<li>
							  <a href="javascript:;" className={(CountryTxt === 'Indonesia')?'active':''} onClick={this.changeCountryFun.bind(this, 'Indonesia')}>
								<div className="header-ordernow-single-img">
									<img src={indonesiaImg} />
									<h3>{lang.countrypopup.countrylable2}</h3>
								</div>
							  </a>
							</li> 
							
						  </ul>
						</div>
					  </div>
					</div>
				  </div>
				  {/* Order popup - end */}
				
				{/* Order popup - start */}
				  <div id="order-popup" className="white-popup mfp-hide popup_sec order_popup">
					<div className="order-body">
					  <h2>{lang.availabilitypopup.title}</h2>
					  <p>{lang.availabilitypopup.subtitle}</p>
					  <div className="order_delivery_row">
						<div className="order_delivery_col">
						  <ul className="order_delivery_item">
						  
							<li>
							  <a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, deliveryId)} className={this.checkActiveDivHd(deliveryId)}>
								<div className="header-ordernow-single-img">
									<img src={deliveryImg} />
									<h3>{lang.availabilitypopup.delivery}</h3>
								</div>
							  </a>
							</li>
							
							<li>
							  <a href="javascript:;" onClick={this.chooseAvailabilityFun.bind(this, pickupId)} className={this.checkActiveDivHd(pickupId)}>
								<div className="header-ordernow-single-img">
									<img src={takeawayImg} />
									<h3>{lang.availabilitypopup.takeaway}</h3>
								</div>
							  </a>
							</li> 
							
						  </ul>
						</div>
					  </div>
					</div>
				  </div>
				  {/* Order popup - end */}
				  
				  {/* Delivery Popup - Start */}
				  {/* <div id="delevery-popup" className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup">
				  
				   <div className="order-body">
					
					  <div className="self_popup_hea_row">
						<div className="self_popup_hea_col_left">
						  <img className="outlet-scooter-img" src={deliveryImg} />
						</div>
						<div className="self_popup_hea_col">
						  <h2>{lang.takeawaypopup.title}</h2>
						  <p>{lang.takeawaypopup.delvry_subtitle}</p>
						</div>
					  </div>
					  
					  <div className="self_pop_row">
						<div className="self_pop_col self_pop_col_right">
						  <div className="self_pop_item">
						  
							<div className="self_pop_locbx"> 
								<div className="form-group">
								  <div className="focus-out">
									<label>{lang.takeawaypopup.searchoutletlbl}</label>
									<input type="input" className="form-control input-focus" onKeyUp={this.handleKeyPressDly} />
									<div className="outlet_error"></div>
								  </div>
								</div>
							</div>
				
							<div className="self_outlet">
								<h2>{lang.takeawaypopup.innerlbl}</h2>
								<ul className="self_outlet_inner">
								{ this.loadDeliveryOutletsList() }
								</ul>
								<a className="button" href="javascript:void(0);" onClick={this.selectDlyOutlet.bind(this)}>{lang.takeawaypopup.continuebtn}</a>
							</div>
							
						  </div>
						</div>
					  </div>
					  
				  </div>

				  </div> */}
				  
				  {/* Delivery Popup - end */}
				  
				  
				  
				  {/*  Delevery Popup - start */}
				  
				  {/* <div id="delevery-postcode-popup" className="white-popup mfp-hide popup_sec delevery_popup"> */}
				  <div id="delevery-popup" className="white-popup mfp-hide popup_sec delevery_popup">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title">
						    {/*<img src={scooterBg} />*/}
							<img className="pop-scooter-img" src={deliveryImg} />
							<h2 className="text-uppercase">{lang.deliverypopup.title}</h2>
							<small>{lang.deliverypopup.subtitle}</small>
						  </div>
						</div>
					  </div>
					  <div className="popup_right">
					  
						{(CountryTxt==='Indonesia') ? <div className="innervmid_in">
							
							
							<div className="form-group">
							  <Autocomplete langArr={lang} onPlaceChanged={this.showPlaceDetails.bind(this)} />
							  <div className="gmap_addrr_error"></div>
							</div>
							
							{/*<div className="btn_sec">
							  <div className="two-button-row">
								<div className="go_second">
								  <a href="javascript:;" onClick={this.gobckOutletpopup.bind(this)} className="button button-left" title={lang.deliverypopup.backbtn}>{lang.deliverypopup.backbtn}</a>
								</div>
								<div className="con_first delivery_submit_cls">
								<input type="button" onClick={this.selectDlyZoneBestGmap.bind(this,deliveryId)} className="button button-right delivery_submit" value={lang.deliverypopup.continuebtn} />
								</div>
							  </div>
						    </div>*/}
							
							<div className="btn_sec delivery_submit_cls delivery_submit_div">
								<input type="button" onClick={this.selectDlyZoneBestGmap.bind(this,deliveryId)} className="button delivery_submit" value={lang.deliverypopup.continuebtn} />
							</div>
							
						</div> : <div className="innervmid_in">
							
							{cookie.load("UserId") &&  <div className="address-list-cls address-list-main">
							{this.userAddressList(1)}
						    </div>}
							
							<div className="form-group">
							  <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
								<label>{lang.deliverypopup.postalcodelbl}</label>
								<input type="text" id="postalcode" pattern="\d*" maxLength="6"  className="form-control input-focus" />
								<div className="postal_error"></div>
							  </div>
							</div>
							
							{/*<div className="btn_sec">
							  <div className="two-button-row">
								<div className="go_second">
								  <a href="javascript:;" onClick={this.gobckOutletpopup.bind(this)} className="button button-left" title={lang.deliverypopup.backbtn}>{lang.deliverypopup.backbtn}</a>
								</div>
								<div className="con_first delivery_submit_cls">
								<input type="button" onClick={this.findOutletBasedZone.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value={lang.deliverypopup.continuebtn} />
								</div>
							  </div>
							</div>*/}
							
							<div className="btn_sec delivery_submit_cls delivery_submit_div">
								<input type="button" onClick={this.findOutletBasedZone.bind(this, 1, deliveryId)} className="button delivery_submit" value={lang.deliverypopup.continuebtn} />
							</div>
							
						</div>}
						
					  </div>
					  
					</div>
				  </div>
				  {/* Delevery Popup - end */}
				  
				  {/* Delevery Popup error - start */}
				  <div id="error-postal-popup" className="white-popup mfp-hide popup_sec error_postal_popup">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title poptt_icontop text-center">
							<img src={iconUnhappy} />
							<h2 className="text-uppercase">{lang.deliveryerrorpopup.title} </h2>
							{/*<small>{lang.deliveryerrorpopup.subtitle}</small>*/}
							<small>We're unable to deliver to your area at the moment.<br></br></small>
							<small>Thank you for your patience.</small>

						  </div>
						</div>
					  </div>
					  <div className="popup_right">
						<div className="innervmid_in">
						 {/*<h4>Enter your postal code</h4>*/}
						 
						 {cookie.load("UserId") &&  <div className="address-list-cls address-list-error">
							{this.userAddressList(2)}
						    </div>}
						 
						  <form className="form_sec">
							<div className="form-group">
							  <div className={(this.state.secondaryaddresslist.length > 0)?"focus-out focused":"focus-out"}>
								<label>{lang.deliveryerrorpopup.postalcodelbl}</label>
								<input type="text" id="postalcode1" pattern="\d*" maxLength="6" className="form-control input-focus" />
								<div className="postal_error"></div>
							  </div>
							</div>
							<div className="btn_sec delivery_submit_cls delivery_submit_div">
							{/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value={lang.deliveryerrorpopup.continuebtn} />*/}
							  <input type="button" onClick={this.findOutletBasedZone.bind(this, 0, deliveryId)} className="button delivery_submit" value={lang.deliveryerrorpopup.continuebtn} />
							</div>
						  </form>
						</div>
					  </div>
					</div>
				  </div>
				  {/* Delevery Popup error - Start */}
				  
				  {/* success popup - Start */}
				  <div id="awesome-popup" className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime">
					<div className="popup_equalrw">
					  <div className="popup_ttsec">
						<div className="innervmid_in">
						  <div className="pop_title">
							<img className="pop-scooter-img" src={iconWin} />
							<h2 className="text-uppercase">{lang.deliverysuccesspopup.title}</h2>
							{(cookie.load('defaultAvilablityId') === deliveryId) && <small>{lang.deliverysuccesspopup.subtitle}</small>}
						  </div>
						  {(this.state.seletedAvilablityId === deliveryId) ? <div className="awesome_del">
							<h5>{lang.deliverysuccesspopup.deliveryaddresslbl} :</h5>
							<h2>{this.state.orderDeliveryAddress}</h2>
						  </div> : <div className="awesome_del">
							<h5>{lang.deliverysuccesspopup.pickupaddresslbl} :</h5>
							<h2>{this.state.orderHandled}</h2>
						  </div>}
						</div>
					  </div>
					  <div className="popup_right">
						<div className="innervmid_in">
							
							<div className="datetime_selt_sec">
							   <div className="datetime_selt_lbl">
							    {(this.state.seletedAvilablityId === deliveryId) ? lang.deliverysuccesspopup.deliverydateselectlbl : lang.deliverysuccesspopup.pickupdateselectlbl}
						       </div> 
							   
							   {!currenturl.includes(isCheckout) && <OrderdatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} />}
							   
							   <div className="ordrdatetime_error"></div>
							</div>
							
							<div className="btn_sec">
							  <input type="button" onClick={this.setOrderOutletDateTimeData.bind(this)} className="button" value={lang.deliverysuccesspopup.continuebtn} />
							</div>
							
						</div>
					  </div>
					</div>
				  </div>
				  {/* success popup - end */}
				  
				  {/* error Popup - start */}
				  <div id="outlet-error-popup" className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup">
					<div className="custom_alert">
						<div className="custom_alertin">
							<div className="alert_height">
							   {/*<div className="alert_header">{lang.warningpopup.title}</div>*/}
								<div className="alert_body">
									<img className="warning-popup-img" src={warningImg} />
									<h2 className="text-uppercase">{lang.deliveryerrorpopup.title}</h2>
									<p>{lang.deliveryerrorpopup.addresserror_lbl1}</p>
									<p>{lang.deliveryerrorpopup.addresserror_lbl2}</p> 
									<div className="alt_btns">
									{(this.state.seletedAvilablityId === pickupId)?<a href="javascript:;" onClick={this.gobckPkupOutletpopup.bind(this)}  className="button button-right popup-modal-dismiss">{lang.deliveryerrorpopup.change_outlet}</a>:<a href="javascript:;" onClick={this.gobckOutletpopup.bind(this)}  className="button button-right popup-modal-dismiss">{lang.deliveryerrorpopup.change_address}</a>}
									</div>
								</div>
							</div>
						</div>
					</div>
				  </div>
				 {/* error Popup - end */}
				  
				  
				  {/* Takeaway Popup - Start */}
				  <div id="takeaway-popup" className="white-popup mfp-hide popup_sec self_popup">
					<div className="order-body">
					
					  <div className="self_popup_hea_row">
						<div className="self_popup_hea_col_left">
						  <img src={takeawayimage} />
						</div>
						<div className="self_popup_hea_col">
						  <h2>{lang.takeawaypopup.title}</h2>
						  <p>{lang.takeawaypopup.subtitle}</p>
						</div>
					  </div>
					  
					  <div className="self_pop_row">
						<div className="self_pop_col self_pop_col_right">
						  <div className="self_pop_item">
							
							<div className="self_pop_locbx"> 
							    {/*<h4>Search Cedele Outlet.</h4>*/}
								<div className="form-group">
								  <div className="focus-out">
									<label>{lang.takeawaypopup.searchoutletlbl}</label>
									<input type="input" className="form-control input-focus" onKeyUp={this.handleKeyPress} />
									<div className="outlet_error"></div>
								  </div>
								</div>
							</div>
							
							<div className="self_outlet">
								<h2>{lang.takeawaypopup.innerlbl}</h2>
								<ul className="self_outlet_inner">
								{ this.loadOutletsList() }
								</ul>
								{/*<a className="button" href="javascript:void(0);" onClick={this.gotoProducts.bind(this)}>Continue</a>*/}
								<a className="button" href="javascript:void(0);" onClick={this.selectDatetm.bind(this)}>{lang.takeawaypopup.continuebtn}</a>
							</div>
							
						  </div>
						</div>
					  </div>
					  
					</div>
				  </div>
				  {/* Takeaway Popup - end */}
				  
				  {/*  Coming Soon Popup - start */}
				  <div id="comingsoon-popup" className="white-popup mfp-hide popup_sec comingsoon_popup">
					
					<div className="order-body">
					  <div className="comingsoon_popup_hea_row">
						<div className="comingsoon_popup_hea_col">
						  <h2>COMING SOON.</h2>
						</div>
					  </div>
					  <div className="comingsoon_pop_row">
						<p> can you select another availability.</p>
						<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button" title="Go Back">Go Back</a>
					  </div>
					 </div> 
				  </div>
				  {/* Coming Soon Popup - end */}
				  
				  {/* Warning Popup - start */}
				  <div id="warning-popup" className="white-popup mfp-hide popup_sec warning_popup">
					<div className="custom_alert">
						<div className="custom_alertin">
							<div className="alert_height">
								<div className="alert_header">{lang.warningpopup.title}</div>
								<div className="alert_body">
									<img className="warning-popup-img" src={warningImg} />
									<p>{lang.warningpopup.subtitle_lang}</p>
									<p>{lang.warningpopup.description}</p> 
									<div className="alt_btns">
										<a href="javascript:;"  className="popup-modal-dismiss button button-left">{lang.warningpopup.nobtn}</a>
										<a href="javascript:;" onClick={this.changeAvailability.bind(this)}  className="button button-right popup-modal-dismiss">{lang.warningpopup.yesbtn}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				  </div>
				 {/* Warning Popup - end */}
				
              </header>
             );
    }
}

const mapStateTopProps = (state) => {

  if(Object.keys(state.settings).length > 0) {
		 if(state.settings[0].status === 'ok') {
			 if(typeof (cookie.load("language")) === "undefined") {
				  if(availLangFiles.indexOf(state.settings[0].result_set.client_language) !== -1) {
					cookie.save("language",state.settings[0].result_set.client_language);
					cookie.save("language_name",state.settings[0].result_set.client_language_name);
					cookie.save("language_flag",state.settings[0].result_set.client_language_flag);
				  } else {
					cookie.save("language",defaultLang);
					cookie.save("language_name",defaultLangName);
					cookie.save("language_flag",defaultLangFlag);
				  }
				  location.reload();
			 }
			 cookie.save("inclusiveGST",state.settings[0].result_set.client_tax_surcharge_inclusive);
		 }
  }

  var zonedetailArr = Array();
  if(Object.keys(state.zonedetail).length > 0) {
		 if(state.zonedetail[0].status === 'ok') {
			 zonedetailArr = state.zonedetail[0].result_set;
		 }
  }  
	
  var outletsArr = Array();
  if(Object.keys(state.outlets).length > 0) {
		 if(state.outlets[0].status === 'ok') {
			 outletsArr = state.outlets[0].result_set;
		 }
  }
  
  var alloutletsArr = Array();
  if(Object.keys(state.alloutlets).length > 0) {
		 if(state.alloutlets[0].status === 'ok') {
			 alloutletsArr = state.alloutlets[0].result_set;
		 }
  }
  
  var secondarydataArr = Array();
  if(Object.keys(state.secondaryaddress).length > 0) {
		 if(state.secondaryaddress[0].status === 'ok') {
			 secondarydataArr = state.secondaryaddress[0].result_set;
		 }
  }
  
  return {
    globalsettings: state.settings,
	zonedetails: zonedetailArr,
    outletslist: outletsArr,
	alloutletslist: alloutletsArr,
	logindata: state.login,
    fbloginData: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
	secondaryaddresslist: secondarydataArr
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
	getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
	getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
	getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
	destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
	getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
	getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
     getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
	getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Header));
