/* eslint-disable */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { stripslashes, showLoader, hideLoader, showAlert} from "../Helpers/SettingHelper";
import { appId, languageCd, apiUrl, apiUrlV2, timThumpUrl } from "../Helpers/Config";
const lang = require('../Helpers/Language/lang'+'-'+languageCd);

var Parser = require('html-react-parser');
import update from 'immutability-helper'
import { validated } from 'react-custom-validation'
import validator from 'validator'

import axios from 'axios';
import cookie from 'react-cookies';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { GET_PICKUP_OUTLETS, GET_CONTACTDATA} from '../../actions';

import ContactUsImg from "../../common/images/contact_banner.jpg";
/*import ContactUsImg from "../../common/images/innerbanner.jpg";*/

var Parser = require('html-react-parser');


class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
			outlet_loaded: 'no',
			outlets_result: [],
			outlets: [],
			outlet_id: '',
			fields: {
				name: '',
				mobile: '',
				subject: '',
				email: '',
				type: '',
				message: '',
			}, pageTitle: '', pageDesc: '', status: '', windowHeight: 0
			};

    }

    fieldChange = (field, value) => {
		this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
	}
	
	handleFormSubmit = () => {
		const formPayload = this.state.fields;

		var qs = require('qs');
		var postObject = {
			"app_id": appId,
			"customer_first_name": formPayload.name,
			"customer_emailaddress": formPayload.email,
			"customer_phonenumber": formPayload.mobile,
			"subject": formPayload.type,
			"customer_message": formPayload.message
		};

		showLoader('act-sbtfunction','class');
        this.props.getContactData(qs.stringify(postObject));

	}

    componentDidMount() {
		this.props.getPickupOutlets();
		$('html, body').animate({ scrollTop: 0 }, 800);
	}
	
	handleShowAlertFun(header,msg) {
		var magnfPopup = $.magnificPopup.instance;
		showAlert(header, msg, magnfPopup);
		$.magnificPopup.open({
		  items: {
			src: '.alert_popup'
		  },
		  type: 'inline'
		});
    }
    
    componentWillReceiveProps(nextProps) {
 
	    if(nextProps.outlets !==this.state.outlets_result && this.state.outlet_loaded === 'no'){
		   if(nextProps.outlets && nextProps.outlets[0].status == 'ok'){
			  var seltd_outlet = nextProps.outlets[0].result_set[0].outlet_id;
			  if(typeof cookie.load('contusOutletId') !== 'undefined') {
				 seltd_outlet = cookie.load('contusOutletId');
				 cookie.remove('contusOutletId');
			  }
              this.setState({outlets_result: nextProps.outlets,outlets: nextProps.outlets[0].result_set,outlet_id: seltd_outlet,outlet_loaded: 'yes'});
           }
        }
		
		if(nextProps.contactdata !== this.props.contactdata)
		{	
	        hideLoader('act-sbtfunction','class');
			if(nextProps.contactdata[0].status === 'ok')
			{
				this.handleShowAlertFun('success',nextProps.contactdata[0].message);

				setTimeout(function () {

					window.location.reload();

				}, 3000);
			}
			else {
				this.handleShowAlertFun('success',nextProps.contactdata[0].message);

				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});

			}
		}

    $('html, body').animate({ scrollTop: 0 }, 800); 

	}
	
	sateValChangefn(outlvalue) {
		this.setState({outlet_id: outlvalue});
	}
	
	callPhoneOptn(phoneTxt) {
		var resultTxt = '';
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = "tel:"+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "tel:+"+phoneTxt;
			} else {
				resultTxt = "tel:+65"+phoneTxt;
			}
		} else {
			resultTxt = 'javascript:void(0);';
		}
		
		return resultTxt;
	}

	getOutletData = () =>{
		  var outLetLst = this.state.outlets;	
		  var outletId = this.state.outlet_id;	
		  if (Object.keys(outLetLst).length > 0) { 
			   var listhtml = outLetLst.map((item, index) =>
						<div key={index} onClick={this.sateValChangefn.bind(this,item.outlet_id)} className={(outletId === item.outlet_id)?"locate-lirow active":"locate-lirow"}>
							<h4>{stripslashes(item.outlet_name)}</h4>
							<p>{item.outlet_address_line1}</p>
							<p>{lang.common.countrylable} ( {item.outlet_postal_code} )</p>
							{(item.outlet_email !== '') && <p>Email: <a href={item.outlet_email}>{item.outlet_email}</a></p>}
							{(item.outlet_phone !== '') && <p>Phone: <span className="phone-show-dskp">{item.outlet_phone}</span><span className="phone-show-mbl"> <a href={this.callPhoneOptn(item.outlet_phone)}>{item.outlet_phone}</a></span></p>}
						</div>
					  );
			   return listhtml;		  
		  } else {
			  return '';
		  }  
	}
	

render() {

  return (<div className={"selected-lang-"+languageCd}>
			<div className="common-top-div"> 
			<Header />
		
			<div className="contact_wrap">
				{/*<div className="inner_banner">
					<img src="https://www.muthuscurry.com/images/banners/banner-restaurants.jpg" />
				</div>*/}
				<div className="inner_banner">
					<img src={ContactUsImg} />
				</div>
				<div>							
					<Form fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} outletListData={this.getOutletData} onInvalid={() => console.log('Form invalid!')} />							
				</div>
			</div>
			
			</div>
			
			<Footer />
			
			<div id="dvLoading1234"></div>
			
		</div>);	

  }
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
	mobile.match(phonenumberPattern) ? null : 'please enter valid Phone number.'

const isEmpty = (value) =>
	value === '' ? 'This field is required.' : null

const isEmail = (email) =>
	validator.isEmail(email) ? null : 'This is not a valid email.'

function validationConfig(props) {
	const { name, mobile, email, message } = props.fields

	return {
		fields: ['name', 'mobile', 'email', 'message'],

		validations: {
			name: [[isEmpty, name]],
			mobile: [[isEmpty, mobile], [isMobile, mobile]],
			email: [[isEmpty, email], [isEmail, email]],
			message: [[isEmpty, message]]
		}
	}
}



class Form extends React.Component {

	state = {
		pageTitle: '',
		pageDesc: ''
	}



	render() {
		
		
		const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
		let errMsgFirstName, errMsgEmail, errMsgMobile, errMsgType, errMsgMessage;
		
		let contactcontent ='';
		
		if(this.props.contactContent != undefined)
		{
			contactcontent = Parser(this.props.contactContent.result_set[0].staticblocks_description);
		}

		if ($validation.name.error.reason !== undefined) {
			document.getElementsByClassName('name').className = 'error';
			errMsgFirstName = ($validation.name.show && <span className="error">{$validation.name.error.reason}</span>)
		}

		if ($validation.mobile.error.reason !== undefined) {
			errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
		}

		if ($validation.email.error.reason !== undefined) {

			errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
		}

		if ($validation.message.error.reason !== undefined) {
			errMsgMessage = ($validation.message.show && <span className="error">{$validation.message.error.reason}</span>)
		}

		return (<div className="inner-page-section">					
					<div className="container">	
				
					<div className="contactus_title_info">
						<h3>ROLL ALONG WITH US</h3>
						<p>We are really friendly people so do join us on our social networking sites and find out more about us.</p>
					</div>	
					
					  <div className="locate-map-main">					
						<div className="locate-mapleft">
						    <h3>Get in touch</h3>
							{this.props.outletListData()} 
						</div>
						<div className="locate-mapright">
							
							<div className="contact_form">
							<h3>Drop us a line</h3>
							<p>For any help, informations or other details</p>
							<form className="form_sec clear">
								
									<div className="form-group">
										<div className="focus-out">
											<label>Name*</label>
											<input type="input" className="form-control input-focus" value={fields.name} maxLength="80"   {...$field('name', (e) => onChange('name', e.target.value))} />
											{errMsgFirstName}
										</div>								
									</div>
									<div className="form-group">
										<div className="focus-out">
											<label>Contact Number*</label>
											<input type="text" className="form-control input-focus" value={fields.mobile} maxLength="11"   {...$field('mobile', (e) => onChange('mobile', e.target.value))} />
											{errMsgMobile}
										</div>
									</div>
									<div className="form-group">
										<div className="focus-out">
											<label>Email*</label>
											<input type="input" className="form-control input-focus" value={fields.email} maxLength="85"   {...$field('email', (e) => onChange('email', e.target.value))} />
											{errMsgEmail}
										</div>
									</div>
								
								{/*<div className="form-group">
										<div className="re_select">
											<select className="form-control select-gender" {...$field('type', (e) => onChange('type', e.target.value)) }>
												<option value=""> Please Select* </option>
												<option value="General Enquiry" id="general-enquiry"> General Enquiry </option>
												<option value="Order Enquiry" id="order-enquiry"> Order Enquiry </option>
												<option value="Others" id="others"> Others </option>
											</select>

											{errMsgType}

										</div>
									</div>*/}
							
									<div className="form-group">
										<div className="focus-out">
											<label>Message*</label>

											<textarea className="form-control input-focus" id="feedback"   {...$field('message', (e) => onChange('message', e.target.value))}></textarea>
											{errMsgMessage}

										</div>
									</div>
									<div className="btn_sec">
										<button type="button" className="btn btn_black btn-lg btn-block act-sbtfunction" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Submit<div className="ripple-container"></div></button><br />

									</div>
								
							</form>
						</div>
							
							
						</div>
						
				  </div>
				</div>
			  </div>
				
			)
	}
}
Form = validated(validationConfig)(Form)

  
const mapStateToProps = (state) => {
  return {
	outlets: state.outlets,
    contactdata: state.contactdata,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	getPickupOutlets: () => {
	  dispatch({ type: GET_PICKUP_OUTLETS});
	},  
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject});
    },
   
  }
}


Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));



